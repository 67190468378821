import createLocalZustand from "context/local-zustand";
import { DataGridColumns, DataGridRows } from "./data-grid-columns";
import { DataGridFetchSettings } from "./data-grid-fetch";

export type DataGridProviderProps = {
  columns: DataGridColumns;
  rows: DataGridRows;
  getRowId: (row: object) => string;
  fetchSettings: Partial<DataGridFetchSettings>;
  isLoading: boolean;
};

export type DataGridContext = {
  setRows: (rows: DataGridRows, replace?: boolean) => void;
  setColumns: (columns: DataGridColumns, replace?: boolean) => void;

  selectedRows: Set<string>;
  setSelectedRow: (
    rowId: string,
    isSelected: boolean,
    replace?: boolean
  ) => void;
  selectAllRows: () => void;
  clearSelectedRows: () => void;
} & DataGridProviderProps;

// TODO fix error when selectableRows is passed as false
export const {
  Context: DataGridContext,
  Provider: DataGridProvider,
  useStore: useDataGridStore,
} = createLocalZustand<DataGridContext>((initialState) => (set, get) => ({
  // Dynamic store data
  columns: {},
  rows: [],
  getRowId: () => "",

  selectedRows: new Set(),
  setSelectedRow: (rowId, newState, replace = false) => {
    set((state) => {
      const selectedRows = state.selectedRows;
      let clonedSelectedRows = new Set(selectedRows);
      newState === true && clonedSelectedRows.add(rowId);
      newState === false && clonedSelectedRows.delete(rowId);
      return { selectedRows: clonedSelectedRows };
    }, replace);
  },
  selectAllRows: () =>
    set((state) => {
      const getRowId = state.getRowId;
      const rowIds = state.rows.map((row) => getRowId(row));
      return { selectedRows: new Set(rowIds) };
    }),
  clearSelectedRows: () => set(() => ({ selectedRows: new Set() })),

  ...initialState,
  fetchSettings: {
    page: 1,
    pageSize: 10,
    ...(initialState?.fetchSettings ?? {}),
  },

  isLoading: true,

  // Static manipulators
  setColumns: (columns, replace = false) =>
    set((state) => {
      return { columns: { ...(!replace && state.columns), ...columns } };
    }),
  setRows: (rows, replace = false) =>
    set((state) => {
      state.clearSelectedRows();
      return { rows: [...(!replace ? state.rows : []), ...rows] };
    }),
  setFetchSettings: (fetchSettings: Partial<DataGridFetchSettings>) =>
    set((state) => {
      return { fetchSettings: { ...state.fetchSettings, ...fetchSettings } };
    }),
  setIsLoading: (isLoading: boolean) => set((state) => ({ isLoading })),
}));
