import { FlexColumn } from "components/basic/flex";
import Text from "components/basic/text";
import BreadCrumb from "components/composed/breadcrumb";
import { DGProvider } from "components/basic/data-grid";
import { useEffect, useState } from "react";
import { DataGridColumns } from "components/basic/data-grid/data-grid-columns.d";
import { Collapse } from "@mantine/core";
import { ManagePropertiesHeader } from "./ManagePropertiesHeader";
import { ManagePropertiesTable } from "./ManagePropertiesTable";
import { ManagePropertiesForm } from "./ManagePropertiesForm";
import { CrudProvider, useCrudStore } from "./crud-store";
import { useDataGridStore } from "components/basic/data-grid/data-grid-store";
import { backendPropertiesCrud } from "services/housien/properties";
import Base from "components/basic/base";
import { useSearchParams } from "react-router-dom";
import { usePagination } from "hooks/use-pagination";
import { withAuthenticationRequired } from "react-oidc-context";

export type FetchSettings = { pageSize: number; page?: number };

const columns: DataGridColumns = {
  __check__: { dataType: "select", label: "Select" },
  id: { dataType: "raw", label: "ID" },
  type: { dataType: "raw", label: "Type" },
  land_area: {
    dataType: "custom",
    label: "Land Area",
    renderer: (cellData: string) => (
      <Text>
        <Text sx={{ fontWeight: 700 }}>{cellData}</Text> m2
      </Text>
    ),
  },
  num_bathrooms: { dataType: "raw", label: "Bathrooms" },
  num_floors: { dataType: "raw", label: "Floors" },
  num_rooms: { dataType: "raw", label: "Rooms" },
  price: { dataType: "currency", label: "Price" },
};

const getRowId = (row: any) => row.id;

type ManagePropertiesProps = {};
const ManageProperties: Component<ManagePropertiesProps> = () => {
  return (
    <CrudProvider initialState={{ fetchSettings: { pageSize: 10, page: 1 } }}>
      <DGProvider initialState={{ columns, rows: [], getRowId }}>
        <FlexColumn>
          <Text variant="h3">Properties</Text>
          <BreadCrumb />
        </FlexColumn>

        <PageContent />
      </DGProvider>
    </CrudProvider>
  );
};

const PageContent: Component = () => {
  const fetchId = useCrudStore((state) => state.fetchId);
  const fetchSettings = useCrudStore((state) => state.fetchSettings);
  const isFormVisible = useCrudStore((state) => state.isFormVisible);
  const isTableVisible = useCrudStore((state) => state.isTableVisible);
  const setIsLoading = useCrudStore((state) => state.setIsLoading);

  const setRows = useDataGridStore((state) => state.setRows);

  const [pagination, setPagination] = usePagination();
  const [numPages, setNumPages] = useState(1);

  useEffect(() => {
    setIsLoading(true);
    backendPropertiesCrud.search({ pagination }).then((searchResult) => {
      const { data: realEstateProperties, metadata } = searchResult;
      setRows(realEstateProperties as any, true);
      setNumPages(metadata.pages);
      setIsLoading(false);
    });
  }, [fetchSettings, fetchId, pagination]);

  return (
    <>
      <ManagePropertiesHeader />
      <Base as={Collapse} in={isFormVisible} sx={{ width: "100%" }}>
        <ManagePropertiesForm />
      </Base>

      <Base as={Collapse} in={isTableVisible} sx={{ width: "100%" }}>
        <ManagePropertiesTable numPages={numPages} />
      </Base>
    </>
  );
};

export default withAuthenticationRequired(ManageProperties);
