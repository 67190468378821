import { createContext, useContext, useRef } from "react";
import { StateCreator, StoreApi, createStore, useStore } from "zustand";

export function createLocalZustand<T>(
  initializer: (initialState: Partial<T>) => StateCreator<T, [], [], T>
) {
  type GenericStoreApi = StoreApi<T>;

  const StoreContext = createContext<GenericStoreApi | null>(null);

  const ContextProvider: ComponentWithChildren = ({
    children,
    initialState,
  }) => {
    const storeRef = useRef<GenericStoreApi>();
    if (!storeRef.current)
      storeRef.current = createStore(initializer(initialState ?? {}));

    return (
      <StoreContext.Provider value={storeRef.current}>
        {children}
      </StoreContext.Provider>
    );
  };

  type SelectorFn<U> = (state: T) => U;
  const useLocalStore = <U,>(selector: SelectorFn<U>): U => {
    const store = useContext(StoreContext);
    if (!store) {
      throw new Error("Missing StoreProvider");
    }
    return useStore(store, selector);
  };

  return {
    Context: StoreContext,
    Provider: ContextProvider,
    Consumer: StoreContext.Consumer,
    useStore: useLocalStore,
  };
}

export default createLocalZustand;
