import Button, { ButtonProps } from "components/basic/button";
import { Flex } from "theme-ui";
import { fieldSizes } from "themes/light/layout";
import { FlattenObjectKeys } from "types/flatten-object-keys";

type ThemeSizes = FlattenObjectKeys<typeof fieldSizes>;

export type InputAdornmentProps = {
  size?: ThemeSizes;
  variant?: "filled" | "button" | "none";
  leftBorder?: boolean;
  rightBorder?: boolean;
  buttonColor?: string;
  buttonVariant?: ButtonProps["variant"];
};

const InputAdornment: ComponentWithChildren<InputAdornmentProps> = ({
  size = "small",
  variant = "filled",
  leftBorder,
  rightBorder,
  buttonColor,
  buttonVariant,
  children,
  sx,
  ...props
}) => {
  return (
    <Flex
      {...(variant === "button" && {
        as: AdornmentButton,
        buttonColor,
        buttonVariant,
      })}
      sx={{
        height: `${size}`,
        width: `${size}`,
        alignItems: "center",
        justifyContent: "center",
        fontSize: 2,
        borderRadius: "0",
        borderStyle: "solid",
        borderColor: "neutral.n3",
        borderWidth: `0 ${rightBorder ? "1px" : "0"} 0 ${
          leftBorder ? "1px" : "0"
        }`,
        ...(variant === "filled" && {
          background: "neutral.n1",
          color: "text.main",
        }),
        ...(variant === "button" && {
          p: 0,
        }),
        ...(variant === "none" && {
          color: "text.main",
        }),
        flexGrow: 0,
        ...sx,
      }}
      {...props}
    >
      {children}
    </Flex>
  );
};

export const AdornmentButton: ComponentWithChildren = ({
  buttonColor,
  buttonVariant,
  children,
  ...props
}) => {
  return (
    <Button color={buttonColor} disableSize variant={buttonVariant} {...props}>
      {children}
    </Button>
  );
};

export default InputAdornment;
