import { User, UserProfile } from "oidc-client-ts";
import { LoadingPage } from "pages/other/loading-page";
import { earlyAdopterGetRequest } from "pages/authenticated/early-adopter/service/requests";
import { useEffect } from "react";
import { useAuth, withAuthenticationRequired } from "react-oidc-context";
import { OpenAPI, UserService } from "services/requests";

function AuthenticationCallback() {
  const auth = useAuth();

  async function CallbackInit() {
    // try {
    //   if (!auth.isLoading && auth.isAuthenticated) {
    //     const response = await earlyAdopterGetRequest(auth.user);
    //     if (response) {
    //       window.location.href = window.location.origin + "/earlyadopter";
    //     } else {
    //       window.location.href =
    //         window.location.origin + "/application/real-states";
    //     }
    //   }
    // } catch (error) {
    //   console.log("Error:", error);
    //   // window.location.href =
    //   //   window.location.origin + "/";
    // }
    window.location.href = window.location.origin + "/earlyadopter";
  }

  useEffect(() => {
    userPostRequest(auth.user).then(response => {
      console.log(response);
      CallbackInit();
    }).catch(err => {
      if (err.status === 400) CallbackInit();
      console.log(err);
    });
  }, [auth]);

  return <LoadingPage />;
}

async function userPostRequest(user: User | null | undefined) {
  OpenAPI.TOKEN = user?.access_token;

  const body = {
    first_name: user?.profile.given_name || "",
    last_name: user?.profile.family_name || ""
  };

  const response = await UserService.createUser(body);
  return response
}

export default withAuthenticationRequired(AuthenticationCallback);
