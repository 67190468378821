import { mockAgencies } from "pages/authenticated/services/requests";
import { AgenciesService, OpenAPI } from "services/requests";

export const getAgencyById = async (token: string | undefined, id: string) => {

    OpenAPI.TOKEN = token

    const response = await AgenciesService.getAgency(id);
    console.log(response);

    return response;

};