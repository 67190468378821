import { DataGridPersonCell } from "../data-grid-cells";
import Text from "../../text";
import { Avatar } from "theme-ui";
import { CellProps, DataGridCell } from "./cell";

export const DGCellPerson: Component<
  CellProps & { cellData: DataGridPersonCell }
> = ({ columnId, columnData, cellData, sx, ...props }) => {
  return (
    <DataGridCell
      columnId={columnId}
      columnData={columnData}
      sx={{ alignItems: "center", gap: 2, ...sx }}
      {...props}
    >
      <Avatar src={cellData.avatarPath} size={30} sx={{ flexShrink: 0 }} />
      <Text sx={{ fontWeight: 600, color: "text.dark" }}>{cellData.name}</Text>
    </DataGridCell>
  );
};
