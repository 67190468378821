import { FlexCenter, FlexColumn, FlexFill } from "components/basic/flex";
import Text from "components/basic/text";
import { CardWithSections } from "components/basic/card/card";
import {
  DGTableContainer,
  DGHeaderColumns,
  DGRows,
} from "components/basic/data-grid";
import { CardSection } from "components/basic/card/card-section";
import { Pagination } from "components/basic/data-fetch/pagination";
import Loading from "components/basic/logos/loading";
import { useCrudStore } from "./crud-store";

type ManagePropertiesTableProps = { numPages: number };
export const ManagePropertiesTable: Component<ManagePropertiesTableProps> = ({
  numPages,
}) => {
  const isLoading = useCrudStore((state) => state.isLoading);

  return (
    <CardWithSections sx={{ width: "100%", background: "background.paper" }}>
      <CardSection
        addLineSeperator
        sx={{ py: 1, pr: 1, alignItems: "center", height: "medium" }}
      >
        <Text variant="h6">Search Results</Text>
        <FlexFill />
      </CardSection>

      {/* <Collapse in={isTableVisible}> */}
      <CardSection size="unset" addLineSeperator>
        <DGTableContainer>
          <DGHeaderColumns />
          {isLoading ? <LoadingSection /> : <DGRows />}
        </DGTableContainer>
      </CardSection>

      <CardSection
        variant="light-contrast"
        sx={{ py: "small", justifyContent: "center" }}
      >
        <Pagination numPages={numPages} />
      </CardSection>
    </CardWithSections>
  );
};

export const LoadingSection: Component = ({}) => {
  return (
    <FlexCenter
      sx={{
        width: "100%",
        background: "neutral.n2",
        p: "huge",
        py: 100,
        gridColumn: "1 / -1",
      }}
    >
      <FlexColumn sx={{ justifyContent: "center", gap: 1 }}>
        <Loading sx={{ height: 100, aspectRatio: "1 / 1", width: "auto" }} />
        <Text variant="h5" sx={{ textAlign: "center", color: "text.faded" }}>
          Carregando...
        </Text>
      </FlexColumn>
    </FlexCenter>
  );
};
