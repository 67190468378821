import { FlexRow } from "components/basic/flex";
import { ButtonProps } from "components/basic/button";
import IconButton from "../icon-button";
import { useSearchParams } from "react-router-dom";
import {
  IconChevronLeft,
  IconChevronLeftPipe,
  IconChevronRight,
  IconChevronRightPipe,
} from "@tabler/icons-react";
import Icon from "../icon";
import { usePagination } from "hooks/use-pagination";

export type PaginationProps = {
  numPages: number;
};
export const Pagination: Component<PaginationProps> = ({ numPages, sx }) => {
  const [searchParams] = useSearchParams();

  const page = Number(searchParams.get("page") ?? "1");

  return (
    <FlexRow
      sx={{
        alignItems: "center",
        width: "min-content",
        gap: 2,
        ...sx,
      }}
    >
      <PageButton {...{ targetPage: 1, page, disabled: page <= 1 }}>
        <Icon as={IconChevronLeftPipe} sx={{ height: "1rem" }} />
      </PageButton>

      <PageButton {...{ targetPage: page - 1, page, disabled: page <= 1 }}>
        <Icon as={IconChevronLeft} sx={{ height: "1rem" }} />
      </PageButton>

      <PageButton
        {...{ targetPage: page, page }}
        disabled
        sx={{ cursor: "default" }}
      >
        {page}
      </PageButton>

      <PageButton
        {...{
          targetPage: page + 1,
          page,

          disabled: page >= numPages,
        }}
      >
        <Icon as={IconChevronRight} sx={{ height: "1rem" }} />
      </PageButton>

      <PageButton
        {...{
          targetPage: numPages,
          page,

          disabled: page >= numPages,
        }}
      >
        <Icon as={IconChevronRightPipe} sx={{ height: "1rem" }} />
      </PageButton>
    </FlexRow>
  );
};

type PageButtonProps = {
  targetPage: any;
  page: number;
} & ButtonProps;
const PageButton: ComponentWithChildren<PageButtonProps> = ({
  page,
  targetPage,
  disabled = false,
  children,
  variant = "outlined",
  sx,
  ...props
}) => {
  const [pagination, setPagination] = usePagination();

  return (
    <IconButton
      variant={variant}
      size="small"
      color="text.main"
      sx={{ px: 1, ...sx }}
      disabled={disabled}
      onClick={() => setPagination({ page: String(targetPage) })}
      {...props}
    >
      {children}
    </IconButton>
  );
};
