import Button from "components/basic/button";
import { ReactNode } from "react";
import { Link, useLocation } from "react-router-dom";
import Icon from "components/basic/icon";

type SideMenuLinkProps = {
  label?: string;
  icon?: ReactNode;
  linkTo: string;
  action?: Function;
};
export const SideMenuLink: Component<SideMenuLinkProps> = ({
  label,
  icon,
  linkTo,
  action,
  sx,
  ...props
}) => {
  const location = useLocation();
  const current = location.pathname + location.hash;
  const isActive = current === linkTo;
  return (
    <Link to={linkTo}>
      <Button
        variant={"ghost-text"}
        fixHover={isActive}
        size="medium"
        sx={{
          fontSize: 17,
          color: "text.main",
          width: "100%",
          justifyContent: "flex-start",
          gap: 1,
          px: 0,
          ...sx,
        }}
        {...props}
      >
        {icon && (
          <Icon sx={{ fontSize: 20, color: "inherit", lineHeight: 0 }}>
            {icon}
          </Icon>
        )}
        {label}
      </Button>
    </Link>
  );
};
