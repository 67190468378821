/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EarlyAdopterRegistration } from '../models/EarlyAdopterRegistration';
import type { SuccessResponse } from '../models/SuccessResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class EarlyAdopterService {

    /**
     * Get Early Adopter Information
     * Returns the early adopter information if it exists, or an empty payload otherwise.
     * @returns EarlyAdopterRegistration Early adopter information retrieved successfully.
     * @throws ApiError
     */
    public static getEarlyAdopter(): CancelablePromise<EarlyAdopterRegistration> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/earlyadopter',
        });
    }

    /**
     * Register Early Adopter Information
     * Registers the early adopter information.
     * @param requestBody 
     * @returns SuccessResponse Early adopter information registered successfully.
     * @throws ApiError
     */
    public static postEarlyAdopter(
requestBody: EarlyAdopterRegistration,
): CancelablePromise<SuccessResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/earlyadopter',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
