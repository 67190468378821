import Base from "components/basic/base";
import Button from "components/basic/button";
import { FlexColumn } from "components/basic/flex";
import Icon from "components/basic/icon";
import StepButton from "components/steps-controller/step-button";

type StepProps = {
  index: number;
  stepKey: string;
  stepValue: { icon: React.FunctionComponent; title: string };
};
export const Step: Component<StepProps> = ({ index, stepKey, stepValue }) => {
  return (
    <>
      {index > 0 && (
        <Base
          sx={{
            flexGrow: 1,
            flexShrink: 1,
            height: "3px",
            background: "darken.n2",
            borderRadius: "100px",
          }}
        />
      )}

      <StepButton key={stepKey} stepId={stepKey} disableBehavior>
        {({ stepState }) => (
          <FlexColumn sx={{ alignItems: "center" }}>
            <Button
              variant="solid"
              disableSize
              disableEffects
              sx={{
                p: "8px",
                borderRadius: 100,
                aspectRatio: "1 / 1",
                flexBasis: 1,
                flexShrink: 0,
                ...(stepState == "future" && {
                  background: "text.faded",
                  borderColor: "text.faded",
                }),
              }}
            >
              <Icon sx={{ lineHeight: 0 }}>
                <stepValue.icon />
              </Icon>
            </Button>
          </FlexColumn>
        )}
      </StepButton>
    </>
  );
};
