import { MutableRefObject } from "react";
import { useFormStoreFieldValue, useFormStoreSetters } from "../form-store";
import Base from "components/basic/base";

export type CustomCheckFnParams = {
  selected: boolean;
};

export type CustomCheckProps = {
  name: string;
  value: any;
  checkboxRef?: MutableRefObject<HTMLElement | undefined>;
};
const CustomCheck: ComponentWithChildrenFn<
  CustomCheckFnParams,
  CustomCheckProps
> = ({ name, value, checkboxRef, children }) => {
  const selectList = (useFormStoreFieldValue(name) as any[]) ?? [];
  const isSelected = selectList.includes(value);
  const { setFieldValue } = useFormStoreSetters();

  return (
    <Base
      ref={checkboxRef}
      onClick={(event) => {
        event.preventDefault();
        let newList;
        if (isSelected) newList = selectList.filter((item) => item !== value);
        else newList = [...selectList, value];
        setFieldValue(name, newList);
      }}
      sx={{ display: "contents", cursor: "pointer" }}
    >
      {typeof children === "function" && children({ selected: isSelected })}
      {typeof children !== "function" && <>{children}</>}
    </Base>
  );
};

export default CustomCheck;
