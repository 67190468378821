import { Theme } from "theme-ui";

export const breakpoints = [
  "500px",
  "700px",
  "900px",
  "1200px",
] satisfies Theme["breakpoints"];

export const mediaQueries = {
  "xs+": `@media (min-width: ${breakpoints[0]})`,
  "xs-": `@media (max-width: ${breakpoints[0]})`,
  "sm+": `@media (min-width: ${breakpoints[1]})`,
  "sm-": `@media (max-width: ${breakpoints[1]})`,
  "md+": `@media (min-width: ${breakpoints[2]})`,
  "md-": `@media (max-width: ${breakpoints[2]})`,
  "lg+": `@media (min-width: ${breakpoints[3]})`,
  "lg-": `@media (max-width: ${breakpoints[3]})`,
} satisfies Theme["mediaQueries"];
