import { FlexColumn, FlexFill, FlexRow } from "components/basic/flex";
import Label from "components/form/primitives/label";
import InputContainer from "components/form/primitives/input-container";
import InputFill, {
  InputFillProps,
} from "components/form/primitives/input-fill";
import { InputMessages } from "components/form/primitives/input-message";
import { InfoButton } from "../primitives/tooltip-button";
import { ReactNode } from "react";

export type TextFieldProps = {
  name: string;
  label?: string;
  help?: string;
  disabled?: boolean;
  leftAdornment?: ReactNode;
  rightAdornment?: ReactNode;
} & InputFillProps;

const TextField: Component<TextFieldProps> = ({
  name,
  label,
  help,
  type = "text",
  disabled = false,
  leftAdornment,
  rightAdornment,
  sx,
  ...props
}) => {
  // Store input props to be repassed
  const inputProps = {
    type,
    ...props,
  };

  return (
    <FlexColumn sx={{ ...sx }}>
      <FlexRow sx={{ alignItems: "center" }}>
        {label && <Label name={name}>{label}</Label>}
        <FlexFill />
        {help && <InfoButton text={help ?? ""} />}
      </FlexRow>
      <InputContainer name={name} size={props.size ?? undefined}>
        {leftAdornment}
        <InputFill name={name} {...inputProps} />
        {rightAdornment}
      </InputContainer>
      <InputMessages name={name} />
    </FlexColumn>
  );
};

export const PassField: Component<Omit<TextFieldProps, "type">> = ({
  name,
  ...props
}) => <TextField name={name} type="password" {...props} />;

export const DateField: Component<Omit<TextFieldProps, "type">> = ({
  name,
  ...props
}) => <TextField name={name} type="date" {...props} />;

export default TextField;
