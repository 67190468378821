import Card from "components/basic/card/card";
import { useDataGridStore } from "components/basic/data-grid/data-grid-store";
import { SearchField } from "components/form/custom-fields/search-field";
import FormProvider from "components/form/form-store";
import Button from "components/basic/button";
import IconButton from "components/basic/icon-button";
import {
  IconEdit,
  IconFaceMask,
  IconFilter,
  IconMask,
  IconMasksTheater,
  IconPlus,
  IconSettings,
  IconTrash,
  IconX,
} from "@tabler/icons-react";
import Icon from "components/basic/icon";
import {
  backendPropertiesCrud,
  mockRealEstateProperty,
} from "services/housien/properties";
import { toast } from "react-toastify";
import { useCrudStore } from "./crud-store";
import { DataGridRowData } from "components/basic/data-grid/data-grid-columns";

type ManagePropertiesHeaderProps = {};
export const ManagePropertiesHeader: Component<
  ManagePropertiesHeaderProps
> = ({}) => {
  // CRUD
  const refreshFetch = useCrudStore((state) => state.refreshFetch);
  const isFormVisible = useCrudStore((state) => state.isFormVisible);
  const setIsFormVisible = useCrudStore((state) => state.setIsFormVisible);
  const setIstableExpanded = useCrudStore((state) => state.setIsTableVisible);
  const setEditingEntity = useCrudStore((state) => state.setEditingEntity);
  // DataGrid
  const getRowId = useDataGridStore((state) => state.getRowId);
  const rows = useDataGridStore((state) => state.rows);
  const selectedRows = useDataGridStore((state) => state.selectedRows);
  const clearSelectedRows = useDataGridStore(
    (state) => state.clearSelectedRows
  );

  let selectedRowData: DataGridRowData;
  if (selectedRows.size === 1) {
    selectedRowData = rows.find((row) =>
      selectedRows.has(getRowId(row))
    ) as DataGridRowData;
  }

  const handleNewProperty = async () => {
    clearSelectedRows();
    setEditingEntity(null);
    setIsFormVisible(true);
    setIstableExpanded(false);
  };

  const handleDelete = async (id: string) => {
    await backendPropertiesCrud.exclude({ id });
  };

  const handleDeletes = async (ids: string[]) => {
    try {
      ids.forEach(async (id) => await handleDelete(id));
      toast.info("Records delete successfully");

      refreshFetch();
    } catch (error) {
      console.log(error);
      toast.error(
        "Could not delete records, please try again later. If the problem persist, call the support."
      );
    }
  };

  const handleEdit = () => {
    setEditingEntity(selectedRowData);
    setIsFormVisible(true);
    setIstableExpanded(false);
    console.log(selectedRowData);
  };

  const handleCancel = () => {
    setEditingEntity(null);
    setIsFormVisible(false);
    setIstableExpanded(true);
    clearSelectedRows();
  };

  return (
    <Card size="small" sx={{ width: "100%", gap: 1 }}>
      <FormProvider>
        {isFormVisible && (
          <Button
            variant="outlined"
            color="error.main"
            size="small"
            onClick={handleCancel}
            sx={{ gap: 1 }}
          >
            <Icon as={IconX} sx={{ width: "1.2rem" }} />
            Cancel
          </Button>
        )}

        {!isFormVisible && (
          <>
            <Button size="small" onClick={handleNewProperty} sx={{ gap: 1 }}>
              <Icon as={IconPlus} sx={{ width: "1.2rem" }} />
              New Property
            </Button>

            <Button size="small" variant="outlined" sx={{ gap: 1 }}>
              <Icon as={IconFilter} sx={{ width: "1.2rem" }} />
              Filters
            </Button>

            <Button
              size="small"
              variant="outlined"
              color="purple"
              sx={{ gap: 1 }}
              onClick={async () => {
                await backendPropertiesCrud.insert({
                  body: mockRealEstateProperty(1) as any,
                });
                refreshFetch();
              }}
            >
              <Icon as={IconMasksTheater} sx={{ width: "1.2rem" }} />
              Add Random
            </Button>
          </>
        )}

        <SearchField sx={{ flexGorw: 1 }} />

        {!isFormVisible && (
          <>
            <Button
              variant="outlined"
              sx={{ gap: 1 }}
              disabled={selectedRows.size !== 1}
              onClick={() => handleEdit()}
            >
              <Icon as={IconEdit} sx={{ width: "1.2rem" }} /> Edit
            </Button>

            <Button
              variant="outlined"
              color="error.main"
              sx={{ gap: 1 }}
              disabled={selectedRows.size < 1}
              onClick={() => handleDeletes([...selectedRows])}
            >
              <Icon as={IconTrash} sx={{ width: "1.2rem" }} /> Delete
            </Button>

            <IconButton variant="outlined">
              <Icon as={IconSettings} />
            </IconButton>
          </>
        )}
      </FormProvider>
    </Card>
  );
};
