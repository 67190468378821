import Base from "components/basic/base";
import { useStepState, useStepsContext } from "./steps-context";
import { ThemeUIStyleObject } from "theme-ui";

type StepButtonProps = {
  stepId: string;
  disableBehavior?:boolean,
  stateSx?: {
    current?: ThemeUIStyleObject;
    future?: ThemeUIStyleObject;
    history?: ThemeUIStyleObject;
  };
};

type StepButtonParam = { stepState: "history" | "current" | "future" };
const StepButton: ComponentWithChildrenFn<StepButtonParam, StepButtonProps> = ({
  stepId,
  disableBehavior=false,
  sx,
  stateSx,
  children,
  ...props
}) => {
  const stepsContext = useStepsContext();
  const { setCurrentStepId } = stepsContext;
  const stepState = useStepState(stepId, stepsContext);
  return (
    <Base
      onClick={() => !disableBehavior && setCurrentStepId(stepId)}
      sx={{
        display: "content",
        ...(stepState === "current" && stateSx?.current),
        ...(stepState === "future" && stateSx?.future),
        ...(stepState === "history" && stateSx?.history),
        ...sx,
      }}
      {...props}
    >
      {typeof children == "function" ? children({ stepState }) : children}
    </Base>
  );
};

export default StepButton;
