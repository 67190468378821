import { DataGridRow } from "components/basic/data-grid/row";
import { useDataGridStore } from "./data-grid-store";

export const DataGridRows: Component = () => {
  const rows = useDataGridStore((state) => state.rows);
  const getRowId = useDataGridStore((state) => state.getRowId);

  return (
    <>
      {rows.map((row) => (
        <DataGridRow key={getRowId(row)} rowData={row} />
      ))}
    </>
  );
};

export const Cols = () => {
  return <div>rows</div>;
};
