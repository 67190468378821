import PriceCard from "./price-card-2";
import HouseTier0 from "components/svg/house-tier-0";
import HouseTier2 from "components/svg/house-tier-2";
import HouseTier4 from "components/svg/house-tier-4";
import HouseTier5 from "components/svg/house-tier-5";
import { PaymentPeriodType } from ".";
import {
  IconBuilding,
  IconBuildingCommunity,
  IconBuildingSkyscraper,
  IconHome,
  IconHome2,
  IconStar,
} from "@tabler/icons-react";

const iconProps = {
  color: "primary.main",
  sx: {
    height: "35px",
    width: "35px",
  },
};
const discounts = {
  monthly: 0,
  quarterly: 5,
  annual: 15,
};
type PriceCardsProps = { paymentPeriod: PaymentPeriodType };
export const PriceCards: Component<PriceCardsProps> = ({ paymentPeriod }) => {
  return (
    <>
      <PriceCard
        href={`http://localhost:5000/oauth2/login?period=${paymentPeriod}&plan=basic`}
        icon={IconHome}
        title="BÁSICO"
        price={115}
        discount={discounts[paymentPeriod]}
        features={[
          { label: "Usuários", quantity: 2 },
          { label: "Imóveis", quantity: 150 },
          { label: "Contratos", quantity: "infinite" },
          { label: "Gestão de locação", check: true },
          { label: "Gestão de venda", check: true },
          { label: "Preço por usuário adicional", value: 80 },
          { label: "Preço por imóvel adicional", value: 1.5 },
        ]}
        backgroundVariant={1}
      />

      <PriceCard
        href={`http://localhost:5000/oauth2/login?period=${paymentPeriod}&plan=advanced`}
        icon={IconBuilding}
        title="DESBRAVADOR"
        price={235}
        discount={discounts[paymentPeriod]}
        features={[
          { label: "Usuários", quantity: 5 },
          { label: "Imóveis", quantity: 500 },
          { label: "Contratos", quantity: "infinite" },
          { label: "Gestão de locação", check: true },
          { label: "Gestão de venda", check: true },
          { label: "Preço por usuário adicional", value: 40 },
          { label: "Preço por imóvel adicional", value: 0.4 },
        ]}
        backgroundVariant={2}
      />

      <PriceCard
        href={`http://localhost:5000/oauth2/login?period=${paymentPeriod}&plan=pro`}
        icon={IconBuildingCommunity}
        title="PROFISSIONAL"
        price={350}
        discount={discounts[paymentPeriod]}
        features={[
          { label: "Usuários", quantity: 10 },
          { label: "Imóveis", quantity: 2000 },
          { label: "Contratos", quantity: "infinite" },
          { label: "Gestão de locação", check: true },
          { label: "Gestão de venda", check: true },
          { label: "Preço por usuário adicional", value: 30 },
          { label: "Preço por imóvel adicional", value: 0.15 },
        ]}
        backgroundVariant={3}
      />

      <PriceCard
        href={`http://localhost:5000/oauth2/login?period=${paymentPeriod}&plan=premium`}
        icon={IconStar}
        title="PREMIUM"
        price={470}
        discount={discounts[paymentPeriod]}
        features={[
          { label: "Usuários", quantity: 20 },
          { label: "Imóveis", quantity: 5000 },
          { label: "Contratos", quantity: "infinite" },
          { label: "Gestão de locação", check: true },
          { label: "Gestão de venda", check: true },
          { label: "Preço por usuário adicional", value: 20 },
          { label: "Preço por imóvel adicional", value: 0.08 },
        ]}
        backgroundVariant={4}
      />
    </>
  );
};
