export type CepData = {
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
  ibge: string;
  gia: string;
  ddd: string;
  siafi: string;
};

export type CepReturn = {
  data?: CepData;
  error?: string;
};

export type GetCepData = (cep: string) => Promise<CepReturn>;

const getCepData: GetCepData = async (cep) => {
  await new Promise((resolve, reject) => {
    setTimeout(resolve, 1000);
  });
  const URL = `https://viacep.com.br/ws/${cep}/json/`;
  try {
    if (!/^[0-9]+$/.test(cep) || cep.length !== 8) {
      console.error("The Zip code is invalid");
      return { error: "The Zip code is invalid" };
    }

    const response = await fetch(URL);
    const cepData = await response.json();

    if (cepData?.erro) {
      console.error("The zip code is invalid");
      return { error: "The Zip code is invalid" };
    }

    return { data: cepData };
  } catch (error) {
    throw new Error("Could not retrieve CEP data from API\n" + error);
  }
};

export default getCepData;
