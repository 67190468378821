/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AgencyInput } from '../models/AgencyInput';
import type { AgencyModel } from '../models/AgencyModel';
import type { SuccessResponse } from '../models/SuccessResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AgenciesService {

    /**
     * Create a new agency
     * Register a new agency.
     * @param requestBody 
     * @returns SuccessResponse Agency created successfully.
     * @throws ApiError
     */
    public static createAgency(
requestBody: AgencyInput,
): CancelablePromise<SuccessResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/agencies',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get All Agencies for User
     * Retrieve all agencies associated with the current user.
     * @returns AgencyModel Agencies retrieved successfully.
     * @throws ApiError
     */
    public static getAllAgencies(): CancelablePromise<Array<AgencyModel>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/agencies',
        });
    }

    /**
     * Get Agency Information
     * Retrieve agency information based on the agency ID.
     * @param agencyId The ID of the agency.
     * @returns AgencyModel Agency information retrieved successfully.
     * @throws ApiError
     */
    public static getAgency(
agencyId: string,
): CancelablePromise<AgencyModel> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/agencies/{agencyId}',
            path: {
                'agencyId': agencyId,
            },
        });
    }

}
