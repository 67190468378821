import React from "react";
import Base from "components/basic/base";

type IconProps = {};

const Icon: Component<IconProps> = ({ icon, sx, ...props }) => {
  return <Base as={icon} sx={{ ...sx }} {...props} />;
};

export default Icon;
