import { FlexRow } from "components/basic/flex";
import Label from "../primitives/label";
import Radio from "../primitives/radio";
import { useRef } from "react";

// TODO Control checkbox by clicking at the label
type RadioFieldProps = { name: string; value: any };
const RadioField: Component<RadioFieldProps> = ({ name, label, value }) => {
  const radioRef = useRef<HTMLElement>();
  return (
    <FlexRow sx={{ alignItems: "center", gap: 1, cursor: "pointer" }}>
      <Radio name={name} value={value} radioRef={radioRef} />
      <Label
        name={name}
        sx={{ cursor: "inherit" }}
        onClick={() => radioRef?.current?.click()}
      >
        {label}
      </Label>
    </FlexRow>
  );
};

export default RadioField;
