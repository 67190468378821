import { WebStorageStateStore } from "oidc-client-ts";
import { LoadingPage } from "pages/other/loading-page";
import { ReactElement, ReactNode } from "react";
import { AuthProvider, useAuth as _useAuth } from "react-oidc-context";
import { Route } from "react-router-dom";

export const {
  REACT_APP_AUTHORITY_URL = "",
  REACT_APP_CLIENT_ID = "",
  REACT_APP_REDIRECT_URI = "",
  REACT_APP_LINK_AUTHIEN = "",
} = process.env;

// OIDC configuration
const oidcConfiguration = {
  authority: REACT_APP_AUTHORITY_URL,
  client_id: REACT_APP_CLIENT_ID,
  redirect_uri: `${window.location.origin}/authentication/callback`,
  response_type: "code",
  scope: "openid",
  userStore: new WebStorageStateStore({
    prefix: "oidc:user",
    store: window.localStorage,
  }),
  stateStore: new WebStorageStateStore({
    prefix: "oidc:state",
    store: window.localStorage,
  }),
  loadUserInfo: true,
  automaticSilentRenew: true,
};

export const AuthenticationProvider: ComponentWithChildren = ({ children }) => {
  return <AuthProvider {...oidcConfiguration}>{children}</AuthProvider>;
};

export const useAuth = _useAuth;

export type ProtectedRouteProps = {
  element: ReactNode;
}

export default AuthenticationProvider;
