/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SuccessResponse } from '../models/SuccessResponse';
import type { UserInput } from '../models/UserInput';
import type { UserResponse } from '../models/UserResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserService {

    /**
     * Register a new user
     * Register a new user with the given information
     * @param requestBody 
     * @returns SuccessResponse User registered successfully.
     * @throws ApiError
     */
    public static createUser(
requestBody: UserInput,
): CancelablePromise<SuccessResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/user',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get user information
     * Retrieve information of an authenticated user.
     * @returns UserResponse User retrieved successfully.
     * @throws ApiError
     */
    public static getUser(): CancelablePromise<UserResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user',
        });
    }

}
