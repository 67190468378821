import Base from "components/basic/base";

type Props = {
  variant?: string;
};

const Text: ComponentWithChildren<Props> = ({
  variant = "empty",
  children,
  sx,
  ...props
}) => {
  if (!variant.startsWith("text.")) variant = "text." + variant;
  return (
    <Base
      as="span"
      sx={{
        variant: variant,
        color: "text.main",
        ...sx,
      }}
      {...props}
    >
      {children}
    </Base>
  );
};

export default Text;
