import Text from "./text";

type LinkProps = { onlyBehavior?: boolean };
const Link: ComponentWithChildren<LinkProps> = ({
  onlyBehavior = false,
  children,
  sx,
  ...props
}) => {
  return (
    <Text
      as="a"
      sx={{
        ...(!onlyBehavior && {
          color: "text.link",
          ":hover": { color: "text.linkHover" },
        }),
        ...(onlyBehavior && {
          color: "inherit",
          display: "contents",
          textDecoration: "inherit",
        }),
        ...sx,
      }}
      {...props}
    >
      {children}
    </Text>
  );
};

export default Link;
