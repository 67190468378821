import {
  IconBuilding,
  IconCreditCard,
  IconMapPin,
  IconRocket,
} from "@tabler/icons-react";
import Base from "components/basic/base";
import Button from "components/basic/button";
import { CardWithSections } from "components/basic/card/card";
import CardHeader from "components/basic/card/card-header";
import { CardSection } from "components/basic/card/card-section";
import { FlexColumn, FlexRow } from "components/basic/flex";
import Icon from "components/basic/icon";
import Text from "components/basic/text";
import StepButton from "components/steps-controller/step-button";
import StepContent from "components/steps-controller/step-content";
import StepsProvider from "components/steps-controller/steps-context";
import RegisterAgency from "pages/authenticated/register-agency/sections/agency";
import RegisterPayment from "pages/authenticated/register-agency/sections/payment";
import RegisterPlans from "pages/authenticated/register-agency/sections/plans";
import RegisterRealState from "pages/authenticated/register-agency/real-state";

const CreateRealState = () => {
  const content = {
    plan: {
      label: "Plano",
      icon: IconRocket,
      content: RegisterPlans,
    },
    payment: {
      label: "Pagamento",
      icon: IconCreditCard,
      content: RegisterPayment,
    },
    realState: {
      label: "Imobiliária",
      icon: IconBuilding,
      content: RegisterRealState,
    },
    address: {
      label: "Endereço",
      icon: IconMapPin,
      content: RegisterAgency,
    },
  };

  return (
    <FlexRow
      sx={{
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        width: "100%",
      }}
    >
      <CardWithSections
        sx={{
          m: 2,
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          minWidth: 800,
        }}
      >
        <CardHeader>
          <Text variant="h3" sx={{ textAlign: "center" }}>
            Registrando sua imobiliária
          </Text>
        </CardHeader>

        <StepsProvider stepIdOrder={Object.keys(content)}>
          <CardSection addLineSeperator sx={{ background: "rgba(0,0,0,0.03)" }}>
            <FlexRow
              sx={{
                width: "100%",
                alignItems: "center",
                gap: 1,
              }}
            >
              {Object.entries(content).map(([key, value], index) => {
                return (
                  <>
                    {index > 0 && (
                      <Base
                        sx={{
                          flexGrow: 1,
                          flexShrink: 1,
                          height: "3px",
                          background: "darken.n2",
                          borderRadius: "100px",
                          mb: 28,
                        }}
                      />
                    )}

                    <StepButton key={key} stepId={key}>
                      {({ stepState }) => (
                        <FlexColumn
                          sx={{ alignItems: "center", width: "100px" }}
                        >
                          <Button
                            variant="solid"
                            disableSize
                            sx={{
                              borderWidth: 2,
                              p: "12px",
                              borderRadius: 100,
                              aspectRatio: "1 / 1",
                              flexBasis: 1,
                              flexShrink: 0,
                              ...(stepState == "future" && {
                                background: "text.faded",
                                borderColor: "text.faded",
                              }),
                            }}
                          >
                            <Icon sx={{ lineHeight: 0 }}>
                              <value.icon size={30} />
                            </Icon>
                          </Button>
                          <Text
                            sx={{
                              pt: 1,
                              color: "primary.dark2",
                              cursor: "pointer",
                              ...(stepState == "future" && {
                                color: "text.main",
                              }),
                              fontWeight: 400,
                              //fontFamily: "sans-serif",
                            }}
                          >
                            {value.label}
                          </Text>
                        </FlexColumn>
                      )}
                    </StepButton>
                  </>
                );
              })}
            </FlexRow>
          </CardSection>

          <CardSection>
            {Object.entries(content).map(([key, value]) => {
              return (
                <StepContent
                  as={FlexColumn}
                  stepId={key}
                  key={key}
                  sx={{ gap: 2, width: "100%" }}
                >
                  <Text
                    variant="h4"
                    sx={{
                      width: "100%",
                      textAlign: "center",
                      display: "block",
                    }}
                  >
                    {value.label}
                  </Text>
                  {/* <value.content /> */}
                </StepContent>
              );
            })}
          </CardSection>
          {/* <Spacer sx={{ pt: 1 }} />
            <FormSteps
              content={content}
              onStepChange={(step) => setActiveStep(step)}
            /> */}
        </StepsProvider>
      </CardWithSections>
    </FlexRow>
  );
};

export default CreateRealState;
