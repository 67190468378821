/** @jsxImportSource theme-ui */

import { forwardRef } from "react";

const Base: ComponentWithChildren = forwardRef(
  ({ as: Component = "div", sx, forwardRef, ...props }, ref) => {
    return <Component ref={forwardRef ?? ref} sx={sx} {...props}></Component>;
  }
);

export default Base;
