import Base from "components/basic/base";
import { cssKeyframes } from "styles/animations";
import { useFormStoreFieldValue, useFormStoreSetters } from "../form-store";
import { MutableRefObject } from "react";

type RadioProps = {
  name: string;
  selected?: boolean;
  value: any;
  radioRef?: MutableRefObject<HTMLElement | undefined>;
};
const Radio: Component<RadioProps> = ({
  name,
  selected: _selected = false,
  value,
  radioRef,
}) => {
  const { setFieldValue } = useFormStoreSetters();
  const selectedValue = useFormStoreFieldValue(name);
  const isSelected = value === selectedValue;

  return (
    <Base
      as="button"
      ref={radioRef}
      sx={{
        appearance: "none",
        background: "none",
        outline: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        borderRadius: "1000px",
        transition: "background 0.2s, box-shadow 0.2s",
        borderStyle: "solid",
        borderWidth: "3px",
        borderColor: "neutral.n5",
        width: "nano",
        height: "nano",
        "&:focus": {
          borderColor: "primary.main",
        },
        ...(isSelected && {
          //background: "primary.main",
          borderColor: "primary.main",
          animation: `${cssKeyframes.fadeIn} 0.2s backwards`,
          "&:focus": {
            borderStyle: "solid",
            borderColor: "primary.main",
          },
        }),
        "&:hover, &:focus": {
          boxShadow: "0px 0px 0px 3px rgba(0,0,0,0.1)",
          cursor: "pointer",
        },
      }}
      // TODO Add validation
      onClick={(event) => {
        event.preventDefault();
        setFieldValue(name, value);
      }}
    >
      {isSelected && (
        <Base
          sx={{
            borderRadius: "1000px",
            background: "primary.main",
            height: "calc(100% - 5px)",
            width: "calc(100% - 5px)",
          }}
        ></Base>
      )}
    </Base>
  );
};

export default Radio;
