import { backendPropertiesCrud } from "services/housien/properties";
import { AgenciesService, OpenAPI } from "services/requests";

export async function agenciesGetRequest(
  token: string | undefined
): Promise<Array<Record<string, any>>> {
  OpenAPI.TOKEN = token;
  const response = await AgenciesService.getAllAgencies();

  console.log(response);

  if (Array.isArray(response)) {
    return response;
  } else {
    return [];
  }
}

export async function agenciesPostRequest(
  agencyDatas: Record<string, string>,
  token: string | undefined
): Promise<Record<string, any>> {
    OpenAPI.TOKEN = token;
    console.log(agencyDatas)

    const agency_data = {
      name: agencyDatas.name,
      description: agencyDatas.description,
      cnpj: agencyDatas.cnpj,
      postal_code: agencyDatas.cep,
      city: agencyDatas.city,
      state: agencyDatas.state,
      number: agencyDatas.number,
      complement: agencyDatas.complement,
      neighborhood: agencyDatas.neighborhood,
      street: agencyDatas.street,
      image: undefined,
    }

    const response = await AgenciesService.createAgency(agency_data);
    console.log(response);
    return response;        
}

export function paymentPostRequest(
  data: Record<string, any>,
  token: string | undefined
) {
  console.log(data);
  return {status_code: 200}
}

function generateAgencyData() { //TODO remover mock
    const names = ["Cosmic Diary Agency", "Stellar Journey Agency", "Galactic Note Agency", "Orbital Diary Agency", "Show Show", "Master Agency", "Travel Agency", "Space Agency", "Star Agency", "Planet Agency"];	
    const descriptions = [
        "An interstellar agency for travelers.",
        "A journey beyond the stars agency.",
        "A galactic notebook for explorers.",
        "An orbital diary service for adventurers."
    ];

    const randomName = names[Math.floor(Math.random() * names.length)];
    const randomDescription = descriptions[Math.floor(Math.random() * descriptions.length)];

    return {
        name: randomName,
        description: randomDescription
    };
}

export const mockAgencies = [
  {
    id: 1,
    name: "Agência 1",
    status: "Status 1",
    address: "Rua 1, 123",
    phone: "(11) 9999-9999",
    email: "agencia1@example.com",
  },
  {
    id: 2,
    name: "Agência 2",
    status: "Status 2",
    address: "Rua 2, 456",
    phone: "(11) 8888-8888",
    email: "agencia2@example.com",
  },
  {
    id: 3,
    name: "Agência 3",
    status: "Status 3",
    address: "Rua 3, 789",
    phone: "(11) 7777-7777",
    email: "agencia3@example.com",
  },
  {
    id: 4,
    name: "Imóveis Show",
    status: "Status 4",
    address: "Rua 4, 012",
    phone: "(11) 6666-6666",
    email: "agencia4@example.com",
  },
];
