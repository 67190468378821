import { DataGridLinkCell } from "../data-grid-cells";
import Link from "../../link";
import { CellProps, DataGridCell } from "./cell";

export const DGCellLink: Component<
  CellProps & { cellData: DataGridLinkCell }
> = ({ columnId, columnData, cellData, sx, ...props }) => {
  return (
    <DataGridCell
      columnId={columnId}
      columnData={columnData}
      sx={{ ...sx }}
      {...props}
    >
      <Link href={cellData.uri}>{cellData?.label ?? cellData.uri}</Link>
    </DataGridCell>
  );
};
