import {
  EarlyAdopterService,
  EarlyAdopterRegistration,
  OpenAPI,
} from "services/requests"
import { jwtDecode } from "jwt-decode"; //TODO Remove Mock
import { EarlyAdopterValues } from "../context";

export async function earlyAdopterGetRequest(token: string | undefined) {
  // let result = false 
  // if (token) {
  //   const decoded = jwtDecode(token); 
  //   const state_storage = localStorage.getItem("earlyadopter");
  //   if (!state_storage?.includes(decoded.sub ?? "")) result = true;
  // }
  OpenAPI.TOKEN = token;

  const response = await EarlyAdopterService.getEarlyAdopter(); 
  console.log(response);

  return response;
}

export async function earlyAdopterPostRequest(
  context: EarlyAdopterValues,
  token: string | undefined
) {
  const earlyadopterDatas: EarlyAdopterRegistration = {
    ocupation: (context.ocupation === "Outra" ? context.other_ocupation : context.ocupation) ?? "",
    city: context.city ?? "",
    state: context.uf ?? "",
    important_function: (context.important_function === "Outras" ? context.others_important_function : context.important_function) ?? "",
    amount_properties: context.amount_properties ?? "",
    amount_employees: context.amount_employees ?? "",
    search_improve: context.search_improve ?? "",
  };

  // if (token) {
  //   const decoded = jwtDecode(token);
  //   const state_storage = localStorage.getItem("earlyadopter");
  //   localStorage.setItem(
  //     "earlyadopter",
  //     decoded.sub + ", " + state_storage
  //   );
  // }

  OpenAPI.TOKEN = token;
  console.log(earlyadopterDatas);
  const response = await EarlyAdopterService.postEarlyAdopter(earlyadopterDatas);
  console.log(response);

  return response;
}
