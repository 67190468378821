import Base from "components/basic/base";

const Hr: Component = ({ sx }) => {
  return (
    <Base
      sx={{
        width: "100%",
        display: "block",
        height: "0.5px",
        background: "neutral.n4",
        ...sx,
      }}
    />
  );
};

export default Hr;
