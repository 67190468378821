import Button from "components/basic/button";
import CustomCheck, { CustomCheckProps } from "./custom-check";
import { BiCheckbox, BiSolidCheckSquare } from "react-icons/bi";
import Icon from "components/basic/icon";

type MultiCheckChipProps = { checkIcon?: boolean } & CustomCheckProps;
const MultiCheckChip: ComponentWithChildren<MultiCheckChipProps> = ({
  checkIcon = true,
  children,
  ...props
}) => {
  return (
    <CustomCheck {...props}>
      {({ selected }) => (
        <Button variant={selected ? "solid" : "outlined"} sx={{ gap: 1 }}>
          {checkIcon && (
            <Icon
              as={selected ? BiSolidCheckSquare : BiCheckbox}
              sx={{
                fontSize: 2,
                mx: "-5px",
                transform: !selected && "scale(1.25)",
              }}
            />
          )}
          {children}
        </Button>
      )}
    </CustomCheck>
  );
};

export default MultiCheckChip;
