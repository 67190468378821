import { DataGridCurrencyCell } from "../data-grid-cells";
import { CellProps, DataGridCell } from "./cell";

export const DGCellCurrency: Component<
  CellProps & { cellData: DataGridCurrencyCell }
> = ({ columnId, columnData, cellData, sx, ...props }) => {
  return (
    <DataGridCell
      columnId={columnId}
      columnData={columnData}
      sx={{ fontWeight: 600, color: "text.dark", ...sx }}
      {...props}
    >
      {"R$ "}
      {Number(cellData).toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}
    </DataGridCell>
  );
};
