import Base from "components/basic/base";
import { useDataGridStore } from "./data-grid-store";
import { DataGridColumnDataInterface } from "./data-grid-columns";

export const DataGridTableContainer: ComponentWithChildren = ({
  children,
  sx,
  ...props
}) => {
  const columns = useDataGridStore((state) => state.columns);

  const columnSizes = Object.values(columns).map(
    (column: DataGridColumnDataInterface) => {
      let columnSize = "1fr";
      if (column.dataType === "select") columnSize = "min-content";
      if (column?.width) columnSize = `${column.width}px`;
      return columnSize;
    }
  );

  // Case there is not fill column, then set the last column to assume the rest of width
  if (columnSizes.every((column) => !column.includes("fr")))
    columnSizes[columnSizes.length - 1] = "1fr";

  const gridTemplateColumns = columnSizes.join(" ");

  return (
    <Base
      sx={{
        width: "100%",
        display: "grid",
        gridTemplateColumns,
        gridTemplateRows: "1fr min-content",
        maxWidth: "100%",
        overflowX: "auto",
        zIndex: 1,
        fontSize: 0,
        ...sx,
      }}
      {...props}
    >
      {children}
    </Base>
  );
};
