import { FormContext } from "./form-store.d";
import { useFormStore } from "./form-store";
import Base from "components/basic/base";

type FormSubmitBehaviorProps = {
  onSubmit: (formStore: FormContext) => void;
  onFallback?: (formStore: FormContext) => void;
  clearAfterSubmit?: boolean;
  noValidate?: boolean;
};
const FormSubmitBehavior: ComponentWithChildren<FormSubmitBehaviorProps> = ({
  onSubmit,
  onFallback,
  clearAfterSubmit = false,
  noValidate = false,
  children,
}) => {
  const get = useFormStore((state) => state.get);
  const validate = useFormStore((state) => state.validate);
  const clearForm = useFormStore((state) => state.clearForm);
  return (
    <Base
      sx={{ display: "contents" }}
      onClick={() => {
        const isValid = validate();
        if (isValid || noValidate) {
          onSubmit(get());
          clearAfterSubmit && clearForm();
        } else {
          onFallback?.(get());
        }
      }}
    >
      {children}
    </Base>
  );
};

export default FormSubmitBehavior;
