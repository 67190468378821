import Text from "components/basic/text";
import Image from "components/basic/image";
import { FlexColumn } from "components/basic/flex";
import Button from "components/basic/button";
import { useNavigate } from "react-router-dom"; 

const Finalize = ({ redirect = "/" }) => {
  const navigate = useNavigate(); 

  return (
    <FlexColumn
      sx={{
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        height: "100%",
        width: "100%",
        flexGrow: 1,
        gap: 3,
      }}
    >
      <Image src="/images/logo/logo-min-color.png" sx={{ height: "100px" }} />
      <Text variant="h3">Obrigado por se juntar à nossa lista de espera!</Text>
      <Text variant="text" sx={{ fontSize: "16px" }}>
        Estamos animados em tê-lo conosco para juntos transformarmos o mercado
        imobiliário. Fale conosco: contato@k4d.com.br
      </Text>
      <Button onClick={() => navigate(redirect)} variant="outlined">
        Início
      </Button>
    </FlexColumn>
  );
};

export default Finalize;
