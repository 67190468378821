import { FlexFill } from "components/basic/flex";
import Text from "components/basic/text";
import { CardWithSections } from "components/basic/card/card";
import { CardSection } from "components/basic/card/card-section";
import FormProvider from "components/form/form-store";
import Button from "components/basic/button";
import IconButton from "components/basic/icon-button";
import { IconEdit, IconPlus, IconX } from "@tabler/icons-react";
import Icon from "components/basic/icon";
import TextField from "components/form/custom-fields/text-field";
import SelectField from "components/form/custom-fields/select-field";
import { useCrudStore } from "./crud-store";
import { backendPropertiesCrud } from "services/housien/properties";
import { useCallback } from "react";
import { toast } from "react-toastify";
import { useDataGridStore } from "components/basic/data-grid/data-grid-store";
import { RealEstateProperty } from "services/housien/types";
import FormSubmitBehavior from "components/form/form-submit";
import { FormContext } from "components/form/form-store.d";

type ManagePropertiesFormProps = {};
export const ManagePropertiesForm: Component<
  ManagePropertiesFormProps
> = () => {
  const setIsFormVisible = useCrudStore((state) => state.setIsFormVisible);
  const setIsTableVisible = useCrudStore((state) => state.setIsTableVisible);
  const editingEntity = useCrudStore((state) => state.editingEntity);
  const refreshFetch = useCrudStore((state) => state.refreshFetch);

  const getRowId = useDataGridStore((state) => state.getRowId);

  const handleCloseForm = useCallback(() => {
    setIsFormVisible(false);
    setIsTableVisible(true);
  }, []);

  const handlePersist = useCallback(async (formData: FormContext) => {
    const { formValues } = formData;
    try {
      if (!editingEntity)
        await backendPropertiesCrud.insert({
          body: formValues as RealEstateProperty,
        });
      else
        await backendPropertiesCrud.update({
          id: getRowId(editingEntity),
          body: formValues as RealEstateProperty,
        });

      toast.success("Data saved successfully");
      refreshFetch();
      handleCloseForm();
    } catch (error) {
      toast.error("Could not persist data");
    }
  }, []);

  return (
    <CardWithSections sx={{ width: "100%", background: "background.paper" }}>
      <CardSection
        addLineSeperator
        sx={{ py: 1, pr: 1, alignItems: "center", height: "medium" }}
      >
        <Text variant="h6">New Property</Text>
        <FlexFill />
        <IconButton
          size="micro"
          variant="ghost-text"
          sx={{ flexGrow: 0 }}
          onClick={handleCloseForm}
        >
          <Icon as={IconX} />
        </IconButton>
      </CardSection>

      <CardSection sx={{ columnGap: 2, pt: 1 }}>
        <FormProvider
          initialValues={editingEntity ?? {}}
          key={editingEntity ? getRowId(editingEntity as object) : "createNew"}
        >
          <SelectField
            label="Type"
            name="type"
            sx={{ flexGrow: 1 }}
            options={{
              land: { value: "land" },
              apartment: { value: "apartment" },
              building: { value: "building" },
              house: { value: "house" },
            }}
          />
          <TextField label="Address" name="address" sx={{ flexGrow: 1 }} />
          <TextField
            label="Price"
            name="price"
            type="number"
            sx={{ flexGrow: 1 }}
          />
          <TextField
            label="Description"
            name="description"
            sx={{ flexGrow: 1 }}
          />
          <TextField
            label="Size"
            name="size"
            type="number"
            sx={{ flexGrow: 1 }}
          />
          <TextField
            label="Num Rooms"
            name="num_rooms"
            type="number"
            sx={{ flexGrow: 1 }}
          />
          <TextField
            label="Num Bathrooms"
            name="num_bathrooms"
            type="number"
            sx={{ flexGrow: 1 }}
          />
          <TextField
            label="Num Floors"
            name="num_floors"
            type="number"
            sx={{ flexGrow: 1 }}
          />
          <TextField
            label="Land Area"
            name="land_area"
            type="number"
            sx={{ flexGrow: 1 }}
          />
          <TextField
            label="Build Year"
            name="build_year"
            type="number"
            sx={{ flexGrow: 1 }}
          />
          <TextField label="Status" name="status" sx={{ flexGrow: 1 }} />
          <TextField
            label="Created At"
            name="created_at"
            type="datetime-local"
            sx={{ flexGrow: 1 }}
          />
          <TextField
            label="Updated At"
            name="updated_at"
            type="datetime-local"
            sx={{ flexGrow: 1 }}
          />

          <FormSubmitBehavior onSubmit={handlePersist}>
            <Button sx={{ gap: 1, width: "100%", mt: 2 }}>
              <Icon
                as={editingEntity ? IconEdit : IconPlus}
                sx={{ width: "1.2rem" }}
              />

              {editingEntity ? "Update" : "Create"}
            </Button>
          </FormSubmitBehavior>
        </FormProvider>
      </CardSection>
    </CardWithSections>
  );
};
