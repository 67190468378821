import { Theme } from "theme-ui";
import { breakpoints } from "themes/light/breakpoints";
import { space, radii, sizes } from "themes/light/layout";
import {
  fonts,
  fontSizes,
  text,
  fontWeights,
  lineHeights,
} from "themes/light/typography";
import { shadows } from "themes/light/shadows";
import { colors } from "themes/light/colors";
import { forms } from "themes/light/forms";

export const lightTheme = {
  // ========= LAYOUT =========
  space,
  radii,
  sizes,
  // ========= RESPONSIVENESS =========
  breakpoints,
  //mediaQueries,
  // ========= COLORS =========
  colors,
  shadows,
  // ========= TYPOGRAPHY =========
  fonts,
  fontSizes,
  fontWeights,
  lineHeights,
  text,
  // ========= VARIANTS =========
  layout: {
    test: { background: "red", border: "2px solid green" },
    test2: { boxShadow: "1px 2px 2px black" },
  },
  //grids: {},
  //buttons: {},
  //links: {},
  //images: {},
  //cards: {},
  forms: forms,
  //badges: {},
  //alerts: {},
  //messages: {},
} satisfies Theme;
