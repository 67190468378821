import { generateCrudFunctions } from "../json-server/json-server-crud-generator";
import { faker } from "@faker-js/faker";
import { RealEstateProperty } from "./types";
import { HOUSIEN_BACKEND_URL } from "./constants";
import { formatDateToDB } from "utils/date";

export const backendPropertiesCrud = generateCrudFunctions<RealEstateProperty>( //TODO atualizar para modelo real quando tiver backend terminado
  HOUSIEN_BACKEND_URL,
  "realEstateProperties"
);

export function mockRealEstateProperty(agencyId: number): RealEstateProperty {
  return {
    property_id: faker.datatype.number(),
    agency_id: agencyId,
    type: faker.helpers.arrayElement([
      "house",
      "land",
      "building",
      "apartment",
    ]),
    address: faker.address.streetAddress(),
    price: faker.datatype.number({ min: 50000, max: 1000000 }),
    description: faker.lorem.sentences(),
    size: faker.datatype.float({ min: 50, max: 1000 }),
    num_rooms: faker.datatype.number({ min: 0, max: 20 }),
    num_bathrooms: faker.datatype.number({ min: 0, max: 10 }),
    num_floors: faker.datatype.number({ min: 1, max: 10 }),
    land_area: faker.datatype.float({ min: 0, max: 1500 }),
    build_year: faker.helpers.rangeToNumber({ min: 1800, max: 2024 }),
    status: faker.helpers.arrayElement(["available", "sold", "rented"]),
    created_at: formatDateToDB(faker.date.past()),
    updated_at: formatDateToDB(faker.date.recent()),
  };
}
