import { CardSection } from "components/basic/card/card-section";
import { FlexColumn } from "components/basic/flex";
import Image from "components/basic/image";
import Text from "components/basic/text";
import { useStepsContext } from "components/steps-controller/steps-context";
import { steps } from "../steps";

export const Header: Component = () => {
    const { currentStepId } = useStepsContext();
    return (
      <CardSection
        addLineSeperator
        direction="horizontal"
        sx={{ gap: 2, alignItems: "center" }}
      >
        <Image src="/images/logo/logo-min-color.png" sx={{ height: "40px" }} />
        <FlexColumn sx={{ gap: "2px" }}>
          <Text variant="h3">Pesquisa de Acesso Antecipado</Text>
          <Text variant="subtitle">{steps[currentStepId].title}</Text>
        </FlexColumn>
      </CardSection>
    );
  };