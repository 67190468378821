import { DataGridBooleanCell } from "../data-grid-cells";
import { IconCheck, IconX } from "@tabler/icons-react";
import Icon from "../../icon";
import { CellProps, DataGridCell } from "./cell";

export const DGCellBoolean: Component<
  CellProps & { cellData: DataGridBooleanCell }
> = ({ columnId, columnData, cellData, sx, ...props }) => {
  return (
    <DataGridCell
      columnId={columnId}
      columnData={columnData}
      sx={{ ...sx }}
      {...props}
    >
      <Icon
        as={cellData ? IconCheck : IconX}
        sx={{ color: cellData ? "green" : "red" }}
        size={19}
      />
    </DataGridCell>
  );
};
