import Base from "components/basic/base";
import Text from "components/basic/text";
import Image from "components/basic/image";
import { FlexCenter, FlexColumn } from "components/basic/flex";
import Button from "components/basic/button";
import { useNavigate } from "react-router-dom"; // Import useNavigate hook
import Separator from "components/basic/separator";
import FormSubmitBehavior from "components/form/form-submit";
import { useStepsContext } from "components/steps-controller/steps-context";

type InviteProps = {
  nextStepId: string;
};

const Invite: React.FC<InviteProps> = ({ nextStepId }) => {
  const stepsContext = useStepsContext();
  const { setCurrentStepId } = stepsContext;

  return (
    <FlexCenter
      sx={{
        flexDirection: "column",
      }}
    >
      {/* <Image src="/images/logo/logo-min-color.png" sx={{ height: "100px" }} /> */}
      <Text variant="h2" sx={{ color: "darken.n5" }}>
        Entre na lista VIP!
      </Text>
      <Separator sx={{ marginBottom: "30px" }} />
      <Text variant="text" sx={{ fontSize: "16px", textAlign: "justify" }}>
        Não perca a chance de moldar o futuro do mercado imobiliário e
        aproveitar todas as vantagens da lista VIP:
      </Text>
      <FlexColumn sx={{ padding: "1em", gap: 3 }}>
        <Text variant="text" sx={{ fontSize: "16px", textAlign: "justify" }}>
          <b>✔ Acesso antecipado: </b>Seja o primeiro a experimentar a
          plataforma e progredir seu negócio.
        </Text>
        <Text variant="text" sx={{ fontSize: "16px", textAlign: "justify" }}>
          <b>✔ Benefício exclusivo: </b>Ganhe um mês de acesso ao Housien.
        </Text>
      </FlexColumn>
      <Text variant="text" sx={{ fontSize: "16px" }}>
        Inscreva-se agora e garanta seu lugar na lista de espera!
      </Text>
      <Separator sx={{ marginBottom: "30px" }} />
      <Button
        sx={{ fontSize: "15px", fontWeight: "bold" }}
        onClick={() => setCurrentStepId(nextStepId)}
      >
        Participar
      </Button>
    </FlexCenter>
  );
};

export default Invite;
