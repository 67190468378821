import { keyframes } from "@emotion/react";

export const cssKeyframes = {
  spin: keyframes({
    "0%": { transform: "rotate(0)" },
    "100%": { transform: "rotate(360deg)" },
  }),

  pulsate: keyframes({
    "0%": { transform: "scale(1)" },
    "50%": { transform: "scale(1.2)" },
    "100%": { transform: "scale(1)" },
  }),

  spinAndPulsate: keyframes({
    "0%": { transform: "scale(1) rotate(0deg)" },
    "50%": { transform: "scale(1.2) rotate(180deg)" },
    "100%": { transform: "scale(1) rotate(360deg)" },
  }),

  fadeIn: keyframes({
    "0%": { transform: "scale(1)" },
    "50%": { transform: "scale(1.2)" },
    "100%": { transform: "scale(1)" },
  }),

  pop: keyframes({
    "0%": { transform: "unset" },
    "50%": { transform: "rotate(1deg) scale(1.03)" },
    "100%": { transform: "unset" },
  }),
};

export const cssAnimations = {
  spin: `${cssKeyframes.spin} 2s linear 0s infinite running`,
  pulsate: `${cssKeyframes.pulsate} 2s linear 0s infinite running`,
  spinAndPulsate: `${cssKeyframes.spinAndPulsate} 2s linear 0s infinite running`,
};
