import { DataGridRow } from "components/basic/data-grid/row";
import { DataGridCell } from "components/basic/data-grid/cell/cell";
import { useDataGridStore } from "./data-grid-store";
import { CheckboxRaw } from "components/form/primitives/checkbox";
import IconButton from "../icon-button";
import Icon from "../icon";
import { IconDotsVertical, IconTableOptions } from "@tabler/icons-react";
import { FlexFill } from "../flex";

export const DataGridHeaderColumns: Component = () => {
  const columns = useDataGridStore((state) => state.columns);
  return (
    <DataGridRow
      sx={{
        background: "neutral.n1",
        fontWeight: 700,
        color: "text.main",
        // textTransform: "uppercase",
        "&:hover": { background: "neutral.n1" },
      }}
    >
      {Object.entries(columns).map(([columnId, columnData]) => {
        if (columnData.dataType === "select")
          return (
            <HeaderSelectCell key={columnId} {...{ columnId, columnData }} />
          );

        return (
          <DataGridCell
            key={columnId}
            {...{ columnId, columnData }}
            sx={{
              // position: "relative",
              gap: 1,
              paddingRight: 1,
              "& .data-grid-options": { opacity: 0 },
              "&:hover .data-grid-options": { opacity: 1 },
              ...columnData.sx,
            }}
          >
            {columnData.label ?? columnId}
            <FlexFill />
            <IconButton
              className="data-grid-options"
              size="micro"
              variant="ghost-text"
              color="text.main"
              sx={{ "&:hover": { background: "neutral.n2" } }}
            >
              <Icon as={IconDotsVertical} sx={{ width: "1rem" }} />
            </IconButton>
          </DataGridCell>
        );
      })}
    </DataGridRow>
  );
};

type HeaderSelectCellProps = { columnId: string; columnData: any };
const HeaderSelectCell: Component<HeaderSelectCellProps> = ({
  columnId,
  columnData,
}) => {
  const rows = useDataGridStore((state) => state.rows);
  const selectedRows = useDataGridStore((state) => state.selectedRows);
  const selectAllRows = useDataGridStore((state) => state.selectAllRows);
  const clearSelectedRows = useDataGridStore(
    (state) => state.clearSelectedRows
  );

  const getRowId = useDataGridStore((state) => state.getRowId);

  const isSelected = rows.every((row) => selectedRows.has(getRowId(row)));

  return (
    <DataGridCell {...{ key: columnId, columnId, columnData }}>
      <CheckboxRaw
        name="__select_all__"
        value={isSelected}
        onToggle={(value) => {
          if (isSelected) clearSelectedRows();
          else selectAllRows();
        }}
      />
    </DataGridCell>
  );
};
