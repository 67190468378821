import Base from "components/basic/base";
import Icon from "components/basic/icon";
import { MutableRefObject, useState } from "react";
import { BiCheck } from "react-icons/bi";
import { cssKeyframes } from "styles/animations";
import { useFormStoreFieldValue, useFormStoreSetters } from "../form-store";

type CheckboxProps = {
  name: string;
  checkboxRef?: MutableRefObject<HTMLElement | undefined>;
};
const Checkbox: Component<CheckboxProps> = ({
  name,
  checkboxRef,
  ...props
}) => {
  const value = Boolean(useFormStoreFieldValue(name));
  const setters = useFormStoreSetters();
  const { setFieldValue } = setters;

  return (
    <CheckboxRaw
      {...{ name, checkboxRef, value }}
      onToggle={(value) => {
        setFieldValue(name, value);
      }}
      {...props}
    ></CheckboxRaw>
  );
};

export const CheckboxRaw: Component<
  CheckboxProps & {
    value: boolean;
    onToggle?: (value: boolean) => void;
  }
> = ({ name, value, checkboxRef, onToggle, ...props }) => {
  return (
    <Base
      as="button"
      ref={checkboxRef}
      sx={{
        appearance: "none",
        background: "none",
        border: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        borderRadius: 1,
        transition: "background 0.2s, box-shadow 0.2s",
        outline: "none",
        borderWidth: "3px",
        borderStyle: "solid",
        borderColor: "neutral.n4",
        width: "nano",
        height: "nano",
        "&:focus": {
          borderColor: "primary.main",
        },
        ...(value === true && {
          borderStyle: "none",
          background: "primary.main",
          animation: `${cssKeyframes.fadeIn} 0.2s backwards`,
          "&:focus": {
            borderStyle: "solid",
            borderColor: "primary.main",
          },
        }),
        "&:hover, &:focus": {
          boxShadow: "0px 0px 0px 3px rgba(0,0,0,0.1)",
          cursor: "pointer",
        },
      }}
      onClick={(event) => {
        event.preventDefault();
        const newValue = !value;
        onToggle?.(newValue);
      }}
    >
      {value && (
        <Icon
          as={BiCheck}
          sx={{ fontSize: 3, flexShrink: 0, color: "white" }}
        />
      )}
    </Base>
  );
};

export default Checkbox;
