import { Theme } from "theme-ui";

/* =========================== Space and variants =========================== */

export const space = [
  0,
  "0.5rem",
  "1rem",
  "1.5rem",
  "2rem",
  "2.5rem",
  "3rem",
  "3.5rem",
] satisfies Theme["space"];

/* export const space = [
  0,
  "0.8rem",
  "1.2rem",
  "1.7rem",
  "2.2rem",
  "2.7rem",
] satisfies Theme["space"]; */

/* export const spaceVariants: Theme["space"] = {
  micro: "0.25rem" space[1].replace('rem','')/2 + 'rem',
  small: "0.5rem",
  medium: "1rem",
  large: "1.5rem",
  huge: "2rem",
}; */

export const spaceVariants: Theme["space"] = {
  micro: Number(String(space[1]).replace("rem", "")) / 2 + "rem",
  small: space[2],
  medium: space[3],
  large: space[4],
  huge: space[5],
};

Object.assign(space, spaceVariants);

/* =========================== Border Radius and variants =========================== */

export const radii = [0, 2, 4, 8, 12, 16, 20, 999] satisfies Theme["radii"];

export const radiiVariatns = {
  micro: 1,
  small: 2,
  medium: 3,
  large: 4,
};

Object.assign(space, spaceVariants);

/* =========================== Sizes =========================== */

export const fieldSizes = {
  nano: "1.4rem",
  micro: "1.8rem",
  small: "2.4rem",
  medium: "3rem",
  large: "3.5rem",
  huge: "5em",
  auto: "auto",
};

export const sizes = {
  ...fieldSizes,

  wide: 1800,
  container: 1024,
  narrow: 512,

  full: "100%",
  half: "50%",
  none: "0",

  // Column System
  col1: `${(1 / 12) * 100}%`,
  col2: `${(2 / 12) * 100}%`,
  col3: `${(3 / 12) * 100}%`,
  col4: `${(4 / 12) * 100}%`,
  col5: `${(5 / 12) * 100}%`,
  col6: `${(6 / 12) * 100}%`,
  col7: `${(7 / 12) * 100}%`,
  col8: `${(8 / 12) * 100}%`,
  col9: `${(9 / 12) * 100}%`,
  col10: `${(10 / 12) * 100}%`,
  col11: `${(11 / 12) * 100}%`,
  col12: "100%",
} satisfies Theme["sizes"];
