import Base from "components/basic/base";
import { FormEvent } from "react";
import { MaskFunction } from "utils/input-masks";
import { useFormStoreSetters, useFormStoreFieldData } from "../form-store";
import { FieldData, FieldValidationFn, FormSetters } from "../form-store.d";

export type OnFieldChange = (
  fieldData: FieldData,
  setters: FormSetters
) => void;

export type OnFieldFocus = (fieldData: FieldData, setters: FormSetters) => void;

export type MaskBehavior = "raw" | "masked";

export type InputFillProps = {
  name: string;
  forwardRef?: React.RefObject<HTMLInputElement>;
  placeholder?: string;
  mask?: MaskFunction;
  maskPersistBehavior?: MaskBehavior;
  validation?: FieldValidationFn;
  validationMoment?: "blur" | "change" | "both";
  onFieldChange?: OnFieldChange;
  onFieldBlur?: OnFieldFocus;
  onFieldFocus?: OnFieldFocus;
  asTextArea?: boolean;
};

const InputFill: Component<InputFillProps> = ({
  name,
  placeholder,
  mask,
  maskPersistBehavior = "raw",
  validation,
  validationMoment = "change",
  onFieldChange,
  onFieldBlur,
  onFieldFocus,
  asTextArea = false,
  forwardRef,
  sx,
  ...props
}) => {
  const fieldData = useFormStoreFieldData(name);
  const { value, error, state = {} } = fieldData;
  const { isDisabled = false } = state;

  const setters = useFormStoreSetters();
  const { setFieldValue, setFieldState, setFieldError } = setters;

  const renderValue = mask?.(value ?? "").masked ?? value ?? "";

  if (asTextArea) props.type = "text";

  const handleInputChange = (event: FormEvent<HTMLInputElement>) => {
    let newValue = event.currentTarget.value;
    let newError = error;

    if (mask) {
      const { unmasked, masked } = mask(newValue);
      if (maskPersistBehavior == "masked") newValue = masked;
      else newValue = unmasked;
    }

    if (["change", "both"].includes(validationMoment)) {
      newError = validation?.({ ...fieldData, value: newValue });
    }

    if (value !== newValue || newError !== error) {
      setFieldValue(name, newValue);
      setFieldError(name, newError);
      onFieldChange?.(
        { ...fieldData, value: newValue, error: newError },
        setters
      );
    }
  };

  const handleBlur = (event: FormEvent<HTMLInputElement>) => {
    setFieldState(name, { isFocused: false });

    let newValue = event.currentTarget.value;
    let newError = error;

    if (["blur", "both"].includes(validationMoment)) {
      newError = validation?.({ ...fieldData, value: newValue });
      if (newError !== error) setFieldError(name, newError);
    }

    onFieldBlur?.({ ...fieldData, value: newValue, error: newError }, setters);
  };

  const handleFocus = (event: FormEvent<HTMLInputElement>) => {
    setFieldState(name, { isFocused: true });
    onFieldFocus?.(fieldData, setters);
  };

  return (
    <Base
      ref={forwardRef}
      as={asTextArea ? "textarea" : "input"}
      disabled={isDisabled}
      name={name ?? name}
      id={name ?? name}
      sx={{
        appearance: "none",
        variant: "text.form.input",
        px: 1,
        border: "none",
        outline: "none",
        flexBasis: "0", // Flex grow will fill the width
        flexGrow: 1,
        flexShrink: 1,
        height: "100%",
        maxWidth: "100%",
        background: "none",
        resize: "none",
        ...(isDisabled && { background: "neutral.n1" }),
        ...sx,
      }}
      value={renderValue}
      onBlur={handleBlur}
      onFocus={handleFocus}
      onChange={handleInputChange}
      placeholder={placeholder}
      {...props}
    />
  );
};
export default InputFill;
