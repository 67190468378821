import { CardSection, CardSectionProps } from "./card-section";

const CardHeader: ComponentWithChildren<CardSectionProps> = ({
  children,
  sx,
  ...props
}) => {
  return (
    <CardSection
      direction="horizontal"
      addLineSeperator
      sx={{ ...sx }}
      {...props}
    >
      {children}
    </CardSection>
  );
};

export default CardHeader;
