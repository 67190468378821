import createLocalZustand from "context/local-zustand";
import { v1 as uuid } from "uuid";

type FetchSettings = { pageSize: number; page?: number; filters?: Filter[] };
type Filter = { column: string; operator: FilterOperator; compareTo: string };
type FilterOperator = "gt" | "lt" | "gte" | "lte" | "e" | "ne";

export type CrudContext = {
  fetchId: string;
  refreshFetch: () => void;

  fetchSettings: FetchSettings;
  setFetchSettings: (fetchSettings: FetchSettings) => void;
  rows: any[];

  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;

  isTableVisible: boolean;
  setIsTableVisible: (value: boolean) => void;
  toggleIsTableExpanded: () => void;

  isFormVisible: boolean;
  setIsFormVisible: (value: boolean) => void;

  editingEntity: Record<string, any> | null;
  setEditingEntity: (entity: Record<string, any> | null) => void;
};
export const {
  Consumer: CrudConsumer,
  Context: CrudContext,
  Provider: CrudProvider,
  useStore: useCrudStore,
} = createLocalZustand<CrudContext>((initializer) => (set, get) => ({
  fetchId: uuid(),
  refreshFetch: () => set((state) => ({ fetchId: uuid() })),

  rows: [],

  isLoading: true,
  setIsLoading: (isLoading) => set((state) => ({ isLoading })),

  isTableVisible: true,
  setIsTableVisible: (value) => set((state) => ({ isTableVisible: value })),
  toggleIsTableExpanded: () =>
    set((state) => ({ isTableVisible: !state.isTableVisible })),

  isFormVisible: false,
  setIsFormVisible: (value) => set((state) => ({ isFormVisible: value })),

  fetchSettings: {
    pageSize: initializer?.fetchSettings?.pageSize ?? 10,
    page: initializer?.fetchSettings?.pageSize ?? 1,
  },
  setFetchSettings: (fetchSettings) =>
    set(() => ({ fetchSettings: fetchSettings })),

  editingEntity: null,
  setEditingEntity: (entity) => set((state) => ({ editingEntity: entity })),
}));
