import Base from "components/basic/base";
import Button from "components/basic/button";
import { Flex, FlexColumn, FlexRow } from "components/basic/flex";
import Separator from "components/basic/separator";
import Text from "components/basic/text";
import { useState } from "react";
import { BiCollapseVertical, BiExpandVertical } from "react-icons/bi";

type SideMenuGroupProps = { label: string; finalSeparator?: boolean };
const SideMenuGroup: ComponentWithChildren<SideMenuGroupProps> = ({
  finalSeparator = true,
  label,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  return (
    <FlexColumn sx={{ gap: 1 }}>
      <FlexRow
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => setIsOpen((previous) => !previous)}
      >
        <Text
          sx={{
            color: "text.main",
            fontWeight: 600,
            fontSize: 0,
            textTransform: "capitalize",
            py: 1,
          }}
        >
          {label}
        </Text>

        <Flex
          sx={{ height: "100%", alignItems: "center", color: "text.faded" }}
        >
          {isOpen ? <BiCollapseVertical /> : <BiExpandVertical />}
        </Flex>
      </FlexRow>

      {isOpen && <FlexColumn>{children}</FlexColumn>}
      {finalSeparator && <Separator direction="col" />}
    </FlexColumn>
  );
};

export default SideMenuGroup;
