import Base from "components/basic/base";

export const Flex: ComponentWithChildren = ({ children, sx, ...props }) => {
  return (
    <Base
      {...props}
      sx={{
        flexBasis: "auto",
        display: "flex",
        ...sx,
      }}
    >
      {children}
    </Base>
  );
};

export const FlexRow: ComponentWithChildren = ({ children, sx, ...props }) => {
  return (
    <Base
      {...props}
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        ...sx,
      }}
    >
      {children}
    </Base>
  );
};

export const FlexColumn: ComponentWithChildren = ({
  children,
  sx,
  ...props
}) => {
  return (
    <Base
      {...props}
      sx={{
        display: "flex",
        flexDirection: "column",
        ...sx,
      }}
    >
      {children}
    </Base>
  );
};

export const FlexCenter: ComponentWithChildren = ({
  children,
  sx,
  ...props
}) => {
  return (
    <Base
      {...props}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        ...sx,
      }}
    >
      {children}
    </Base>
  );
};

export const FlexFill: Component = ({ sx, ...props }) => {
  return <Base sx={{ display: "block", flexGrow: 1, ...sx }} {...props} />;
};
