import { FlexRow } from "components/basic/flex";
import Button from "components/basic/button";
import TextField from "components/form/custom-fields/text-field";
import Form from "components/form/form-store";
import { cnpjMask } from "utils/input-masks";

const RegisterRealState = () => {
  return (
    <Form sx={{ width: "100%" }}>
      <TextField
        name="cnpj"
        label="CNPJ"
        mask={cnpjMask}
        placeholder="00.000.000/0001-00"
      />
      <TextField name="name" label="Nome" />
      <TextField
        name="properties"
        label="Quanto imóveis sua empresa administra?"
        type="number"
        placeholder="0"
      />
      <TextField
        name="employees"
        label="Quantos colaboradores sua empresa possui?"
        type="number"
        placeholder="0"
      />

      {/* <CheckField>Teste</CheckField> */}
      <Button sx={{ width: "full" }}>Avançar</Button>
    </Form>
  );
};

export default RegisterRealState;
