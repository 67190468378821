import { CardSection } from "components/basic/card/card-section";
import { IconBuilding, IconSend, IconUser, IconVip } from "@tabler/icons-react";
import { Step } from "./step-item";

type EarlyAdopterSteps = Record<
  string,
  { icon: React.FunctionComponent; title: string }
>;
export const steps: EarlyAdopterSteps = {
  invite: { icon: IconSend, title: "Bem vindo ao Housien!" },
  userType: { icon: IconUser, title: "Conte mais sobre você" },
  company: { icon: IconBuilding, title: "Com o que está lidando hoje?" },
  finalize: { icon: IconVip, title: "Obrigado pelo seu tempo!" },
};

export const Steps: Component = () => {
  return (
    <CardSection
      addLineSeperator
      sx={{ alignItems: "center", gap: 1 }}
      direction="horizontal"
    >
      {Object.entries(steps).map(([key, value], index) => {
        return <Step index={index} stepKey={key} stepValue={value} key={key} />;
      })}
    </CardSection>
  );
};
