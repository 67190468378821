import { FlexColumn, FlexFill, FlexRow } from "components/basic/flex";
import Label from "components/form/primitives/label";
import InputContainer from "components/form/primitives/input-container";
import InputFill, {
  InputFillProps,
} from "components/form/primitives/input-fill";
import { InputMessages } from "components/form/primitives/input-message";
import { InfoButton } from "../primitives/tooltip-button";
import { ReactNode } from "react";
import { useThemeUI } from "theme-ui";

export type TextAreaFieldProps = {
  name: string;
  label?: string;
  help?: string;
  disabled?: boolean;
  leftAdornment?: ReactNode;
  rightAdornment?: ReactNode;
  rows?: number;
} & InputFillProps;

const TextAreaField: Component<TextAreaFieldProps> = ({
  name,
  label,
  help,
  type = "text",
  disabled = false,
  leftAdornment,
  rightAdornment,
  rows = 1,
  sx,
  ...props
}) => {
  const { theme } = useThemeUI();
  const height = `calc(${theme?.sizes?.[props.size ?? "small"]} * ${rows})`

  // Store input props to be repassed
  const inputProps = {
    type,
    ...props,
  };

  return (
    <FlexColumn sx={{ ...sx }}>
      <FlexRow sx={{ alignItems: "center" }}>
        {label && <Label name={name}>{label}</Label>}
        <FlexFill />
        {help && <InfoButton text={help ?? ""} />}
      </FlexRow>
      <InputContainer name={name} size="auto" sx={{ height: height }}>
        {leftAdornment}
        <InputFill name={name} {...inputProps} asTextArea sx={{ py: "0.5rem" }} />
        {rightAdornment}
      </InputContainer>
      <InputMessages name={name} />
    </FlexColumn>
  );
};

export const PassField: Component<Omit<TextAreaFieldProps, "type">> = ({
  name,
  ...props
}) => <TextAreaField name={name} type="password" {...props} />;

export const DateField: Component<Omit<TextAreaFieldProps, "type">> = ({
  name,
  ...props
}) => <TextAreaField name={name} type="date" {...props} />;

export default TextAreaField;
