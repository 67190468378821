import MultiCheckChip from "../primitives/multi-check-chip";
import FieldGroup from "./field-group";

type MultiCheckChipFieldProps = {
  name: string;
  options: MultiCheckChipOption[];
  label: string;
};
type MultiCheckChipOption = { label: string; value: any };

const MultiCheckChipField: Component<MultiCheckChipFieldProps> = ({
  name,
  options,
  label,
}) => {
  return (
    <FieldGroup label={label} direction="horizontal" sx={{ flexWrap: "wrap" }}>
      {options.map(({ value, label }) => (
        <MultiCheckChip key={value} name={name} value={value}>
          {label}
        </MultiCheckChip>
      ))}
    </FieldGroup>
  );
};

export default MultiCheckChipField;
