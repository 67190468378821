import { DataGridColumnCustom } from "../data-grid-columns";
import { DataGridCustomCell } from "../data-grid-cells";
import { CellProps, DataGridCell } from "./cell";

export const DGCellCustom: Component<
  CellProps & {
    cellData: DataGridCustomCell;
    columnData: DataGridColumnCustom;
    rowData: any;
  }
> = ({ columnId, columnData, cellData, sx, ...props }) => {
  return (
    <DataGridCell
      columnId={columnId}
      columnData={columnData}
      sx={{ ...sx }}
      {...props}
    >
      {columnData.renderer(cellData)}
    </DataGridCell>
  );
};
