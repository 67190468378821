import Icon from "components/basic/icon";
import InputAdornment, { InputAdornmentProps } from ".";
import { BiLoaderCircle } from "react-icons/bi";
import { cssAnimations } from "styles/animations";

const LoadingAdornment: Component<InputAdornmentProps> = ({ ...props }) => {
  return (
    <InputAdornment {...props}>
      <Icon
        as={BiLoaderCircle}
        sx={{ animation: cssAnimations.spinAndPulsate }}
      />
    </InputAdornment>
  );
};

export default LoadingAdornment;
