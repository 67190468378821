import Base from "components/basic/base";
import Card from "./card/card";
import Text from "./text";

type TooltipProps = { label: string };
const Tooltip: ComponentWithChildren<TooltipProps> = ({ label, children }) => {
  return (
    <Base
      sx={{ position: "relative", "&:hover .tooltip": { display: "flex" } }}
    >
      <Card
        className="tooltip"
        size="unset"
        sx={{
          position: "absolute",
          bottom: "100%",
          left: "50%",
          marginLeft: "-50%",
          display: "none",
          p: "micro",
        }}
      >
        <Text sx={{ fontSize: "0.8rem" }}>{label}</Text>
      </Card>
      {children}
    </Base>
  );
};

export default Tooltip;
