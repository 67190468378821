import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { FlexRow } from "components/basic/flex";
import Button from "components/basic/button";

export const DataGridFooter: Component = ({ sx }) => {
  return (
    <FlexRow
      sx={{
        alignItems: "center",
        gap: 1,
        ...sx,
      }}
    >
      <span>1 - 50 of 500</span>
      <Button variant="outlined" size="micro" color="text.main" sx={{ px: 1 }}>
        <BiChevronLeft />
      </Button>
      <Button variant="outlined" size="micro" color="text.main" sx={{ px: 1 }}>
        <BiChevronRight />
      </Button>
    </FlexRow>
  );
};
