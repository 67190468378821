import { FlexColumn, FlexFill, FlexRow } from "components/basic/flex";
import { InfoButton } from "../primitives/tooltip-button";
import InputContainer from "../primitives/input-container";
import InputFill, { InputFillProps } from "../primitives/input-fill";
import { InputMessages } from "../primitives/input-message";
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { Combobox } from "@headlessui/react";
import Base from "components/basic/base";
import Card from "components/basic/card/card";
import Label from "components/form/primitives/label";
import InputAdornment from "../primitives/input-adornment";
import { BiChevronDown } from "react-icons/bi";
import { useFormStoreFieldValue, useFormStoreSetters } from "../form-store";

export type SelectFieldOption = { value: any; label?: string };
export type SelectFieldOptions = Record<string, SelectFieldOption>;

type SelectFieldProps = {
  name: string;
  type?: string;
  label?: string;
  value?: string;
  options: SelectFieldOptions;
  help?: string;
  disabled?: boolean;
  leftAdornment?: ReactNode;
  rightAdornment?: ReactNode;
  onChange?: Function;
} & InputFillProps;

const SelectField: ComponentWithChildren<SelectFieldProps> = ({
  name,
  type,
  value,
  label,
  options,
  help,
  disabled,
  leftAdornment,
  rightAdornment,
  onChange = (value) => {},
  children,
  sx,
  ...props
}) => {
  const [selected, setSelected] = useState<SelectFieldOption | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const setters = useFormStoreSetters();
  const { setFieldValue } = setters;

  const query = useFormStoreFieldValue(name);
  const [isFocused, setIsFocused] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(options);

  useEffect(() => {
    const arrayOptions = Object.entries(options);
    const arrayFilteredOptions =
      query === undefined
        ? arrayOptions
        : arrayOptions.filter(([key, option]) => {
            let result =
              option.value.toLowerCase().substring(0, query.length) ===
              query.toLowerCase();
            if (!isNaN(+query)) result = key.includes(query);
            return result;
          });

    setFilteredOptions(options);
    if (
      arrayFilteredOptions.length > 0 &&
      query != arrayFilteredOptions[0][1].value
    )
      setFilteredOptions(Object.fromEntries(arrayFilteredOptions));
  }, [query, options]);

  const handleChange = (value: any) => {
    if (value) {
      setFieldValue(name, value);
      onChange(value);
      setIsFocused(false);
    }
  };

  return (
    <Combobox value={selected} onChange={handleChange}>
      <FlexColumn sx={{ position: "relative", ...sx }}>
        <FlexRow sx={{ alignItems: "center" }}>
          {label && <Label name={name}>{label}</Label>}
          <FlexFill />
          {help && <InfoButton text={help ?? ""} />}
        </FlexRow>
        <Combobox.Button style={{ display: "contents" }}>
          <InputContainer name={name} sx={{ cursor: "pointer" }}>
            {leftAdornment}
            <InputFill
              forwardRef={inputRef}
              placeholder={type === "search" ? "Insira aqui" : ""}
              name={name}
              value={type === "search" ? query : selected?.value ?? "Selecione"}
              type={type === "search" ? "text" : "button"}
              sx={{ textAlign: "left" }}
              onFieldFocus={() => setIsFocused(true)}
              onFieldBlur={() => setIsFocused(false)}
            />
            {rightAdornment}
            {!rightAdornment && (
              <InputAdornment variant="none" leftBorder>
                <BiChevronDown />
              </InputAdornment>
            )}
          </InputContainer>
        </Combobox.Button>

        <Combobox.Options static={isFocused}>
          <Card
            addOutline
            sx={{
              position: "absolute",
              top: "100%",
              left: 0,
              listStyle: "none",
              flexDirection: "column",
              minWidth: "100%",
              overflowY: "auto",
              maxHeight: "155px",
              zIndex: 1,
            }}
            size="unset"
          >
            {filteredOptions &&
              Object.entries(filteredOptions).map(([key, option]) => (
                <Base
                  key={key}
                  sx={{ display: "contents" }}
                  onClick={() => {
                    setSelected(option);
                  }}
                >
                  <SelectOption value={option.value} setSelected={setSelected}>
                    {option.label ?? option.value}
                  </SelectOption>
                </Base>
              ))}
            {children}
          </Card>
        </Combobox.Options>

        <InputMessages name={name} />
      </FlexColumn>
    </Combobox>
  );
};

type SelectOptionProps = { value: any };
export const SelectOption: ComponentWithChildren<SelectOptionProps> = ({
  value,
  children,
}) => {
  return (
    <Combobox.Option style={{ display: "contents" }} value={value}>
      {({ active, disabled, selected }) => (
        <Base
          value={value}
          sx={{
            py: "micro",
            px: "micro",
            whiteSpace: "nowrap",
            cursor: "pointer",
            color: "text.main",
            fontSize: 0,
            ...(active && {
              background: "primary.light3",
              color: "primary.dark3",
            }),
            ...(selected && {
              background: "primary.main",
              color: "primary.contrastText",
            }),
            ...(disabled && {}),
          }}
        >
          {children}
        </Base>
      )}
    </Combobox.Option>
  );
};

export function convertArrayToSelectFieldOptions(array: any[]) {
  const result = array.reduce(
    (acc: SelectFieldOptions, item: any, index: number) => {
      acc[`option${index + 1}`] = { value: item };
      return acc;
    },
    {}
  );
  return result;
}

export default SelectField;
