import { useState } from "react";
import TextField, { TextFieldProps } from "./text-field";
import { BiHide, BiShow } from "react-icons/bi";
import InputAdornment from "../primitives/input-adornment";

const PasswordField: Component<TextFieldProps> = ({ ...props }) => {
  const [isVisible, setIsVisible] = useState(false);
  return (
    <TextField
      rightAdornment={
        <InputAdornment
          variant="button"
          leftBorder
          onClick={() => setIsVisible(!isVisible)}
        >
          {isVisible ? <BiHide /> : <BiShow />}
        </InputAdornment>
      }
      {...props}
      type={isVisible ? "text" : "password"}
    />
  );
};

export default PasswordField;
