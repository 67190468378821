import Text from "components/basic/text";
import { useFormStoreFieldError } from "../form-store";

type InputMessagesProps = { name: string };
export const InputMessages: Component<InputMessagesProps> = ({ name }) => {
  const fieldError = useFormStoreFieldError(name);

  return (
    <>
      {fieldError && (
        <InputMessage key={fieldError} type="error">
          {fieldError}
        </InputMessage>
      )}
    </>
  );
};

type InputMessage = {
  type: "description" | "error" | "warning" | "info";
};

export const InputMessage: ComponentWithChildren<InputMessage> = ({
  type = "error",
  children,
  sx,
  ...props
}) => {
  return (
    <Text
      variant="text.form.message"
      sx={{
        fontSize: "0.75rem",
        ...(type === "error" && { color: "error.main" }),
        ...sx,
      }}
      {...props}
    >
      {children}
    </Text>
  );
};
