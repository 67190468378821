import { FlexColumn } from "components/basic/flex";

type PageContentProps = { headerHeight: number };
const PageContent: ComponentWithChildren<PageContentProps> = ({
  headerHeight,
  children,
  sx,
  ...props
}) => {
  return (
    <FlexColumn
      sx={{
        flexGrow: 1,
        height: "100%",
        minHeight: `calc(100vh - ${headerHeight}px)`,
        p: 4,
        px: 6,
        justifyContent: "flex-start",
        alignItems: "flex-start",
        gap: 2,
        zIndex: 2,
        overflowX: "clip",
        ...sx,
      }}
      {...props}
    >
      {children}
    </FlexColumn>
  );
};

export default PageContent;
