import Base from "components/basic/base";
import Loading from "components/basic/logos/loading";

export const LoadingPage = () => {
  return (
    <Base
      sx={{
        width: "100%",
        height: "100%",
        flexGrow: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Loading />
    </Base>
  );
};
