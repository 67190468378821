import { Theme } from "theme-ui";

export const fonts = {
  body: '"Roboto", "sans-serif", "Lato", "Segoe UI", "Roboto", -apple-system, BlinkMacSystemFont,"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue"',
  heading: '"Montserrat", sans-serif',
  monospace:
    'source-code-pro, Menlo, Monaco, Consolas, "Courier New",monospace',
} satisfies Theme["fonts"];

export const fontWeights = {
  body: "regular",
  bold: "bold",
} satisfies Theme["fonts"];

export const lineHeights = {
  body: "auto",
  heading: "auto",
} satisfies Theme["fonts"];

export const fontSizes = [
  "0.875rem",
  "0.9rem",
  "1rem",
  "1.15rem",
  "1.31rem",
  "1.58rem",
  "2.1rem",
  "3.15rem",
  "3.78rem",
] satisfies Theme["fontSizes"];

export const text = {
  h1: {
    fontFamily: "heading",
    fontSize: 6,
    fontWeight: "800",
    textTransform: "uppercase",
    color: "text.main",
  },
  h2: {
    fontFamily: "heading",
    fontSize: 5,
    fontWeight: "700",
    color: "text.main",
  },
  h3: {
    fontFamily: "heading",
    fontSize: 4,
    fontWeight: "700",
    color: "text.main",
  },
  h4: {
    fontFamily: "heading",
    fontSize: 3,
    fontWeight: "700",
    color: "text.main",
  },
  h5: {
    fontFamily: "heading",
    fontSize: 2,
    fontWeight: "600",
    color: "text.main",
  },
  h6: {
    fontFamily: "heading",
    fontSize: 1,
    fontWeight: "600",
    color: "text.main",
  },
  subtitle: {
    fontFamily: "heading",
    fontSize: 2,
    fontWeight: "400",
    color: "text.faded",
  },
  text: {
    fontFamily: "body",
    fontSize: 1,
    fontWeight: "500",
    color: "text.secondary",
  },
  interactables: {
    fontWeight: 400,
    lineHeight: "1rem",
    color: "neutral.n7",
    fontSize: 1,
    py: "small",
  },
  form: {
    labels: {
      fontWeight: 400,
      fontSize: 0,
      lineHeight: "0.5rem",
      color: "neutral.n7",
      py: "small",
    },
    message: {
      lineHeight: 0,
      fontSize: 0,
      fontWeight: 400,
      color: "neutral.n5",
      py: "small",
    },
    input: {
      fontWeight: 400,
      fontSize: 0,
      //lineHeight: "0.5rem",
      color: "neutral.n8",
    },
  },
  empty: {},
} satisfies Theme["text"];
