import { DataGridBooleanCell } from "../data-grid-cells";
import { DataGridContext, useDataGridStore } from "../data-grid-store";
import { CellProps, DataGridCell } from "./cell";
import { CheckboxRaw } from "components/form/primitives/checkbox";

export const DGCellSelect: Component<
  CellProps & { cellData: DataGridBooleanCell }
> = ({ rowData, columnId, columnData, cellData, sx, ...props }) => {
  const selectedRows = useDataGridStore((state) => state.selectedRows);
  const setSelectedRow = useDataGridStore((state) => state.setSelectedRow);
  const getRowId = useDataGridStore((state) => state.getRowId);

  const rowId = getRowId(rowData as object);
  const isSelected = selectedRows.has(rowId);

  return (
    <DataGridCell
      columnId={columnId}
      columnData={columnData}
      sx={{ ...sx }}
      {...props}
    >
      <CheckboxRaw
        name="row-select"
        value={isSelected}
        isSelected={cellData}
        onToggle={(isSelected) => {
          setSelectedRow(getRowId(rowData as object), isSelected);
        }}
      />
    </DataGridCell>
  );
};
