import React, { useEffect, useState } from "react";

import ReactMarkdown from "react-markdown";


const ServiceTermsVersion1: React.FC = () => {
  const [content, setContent] = useState("");

  useEffect(() => {
    fetch("/ServiceTermsText.md")
      .then((response) => response.text())
      .then((text) => setContent(text));
  }, []);

  return <ReactMarkdown>{content}</ReactMarkdown>
};

export default ServiceTermsVersion1;