import { CardSection } from "components/basic/card/card-section";
import FormProvider from "components/form/form-store";
import StepContent from "components/steps-controller/step-content";
import { Invite, UserType, Company, Finalize } from "../sections";
import { earlyAdopterContext } from "../context";

export const Content: ComponentWithChildren = ({ children }) => {
  const { useStore } = earlyAdopterContext;
  const values = useStore((state) => state.values);
  return (
    <CardSection
      direction="vertical"
      sx={{ flexGrow: 1, gap: "10px", minHeight: "395px" }}
    >
      <StepContent stepId="invite">
        <Invite nextStepId="userType" />
      </StepContent>
      <StepContent stepId="userType">
        <FormProvider
          required={["ocupation", "city", "uf", "important_function"]}
          initialValues={values}
        >
          <UserType previousStepId="invite" nextStepId="company" />
        </FormProvider>
      </StepContent>
      <StepContent stepId="company">
        <FormProvider
          required={["amount_properties", "amount_employees", "search_improve"]}
          initialValues={values}
        >
          <Company previousStepId="userType" nextStepId="finalize" />
        </FormProvider>
      </StepContent>
      <StepContent stepId="finalize">
        <Finalize />
      </StepContent>
    </CardSection>
  );
};
