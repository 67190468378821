import { Theme } from "theme-ui";

export const forms = {
  label: {},
  field: {},
  inputContainer: {
    variants: {
      outlined: {
        border: "1px solid gray",
        borderColor: "neutral.n4",
        backgroundColor: "background.paper",
        boxShadow: "inset 1px 1px 1px 2px rgba(0,0,0,0.03)",
      },
    },
    status: {
      focus: {},
      error: {},
      success: {},
      disabled: {},
    },
  },
} satisfies Theme["forms"];
