import { DataGridRawCell } from "../data-grid-cells";
import { CellProps, DataGridCell } from "./cell";

export const DGCellRaw: Component<
  CellProps & { cellData: DataGridRawCell }
> = ({ columnId, columnData, cellData, sx, ...props }) => {
  return (
    <DataGridCell
      columnId={columnId}
      columnData={columnData}
      sx={{ ...sx }}
      {...props}
    >
      {cellData}
    </DataGridCell>
  );
};
