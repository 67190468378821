import createLocalZustand from "context/local-zustand";
import { produce } from "immer";
import { createContext } from "react";
import { useShallow } from "zustand/react/shallow";

export interface AgencyValues {
  adder?: string;
  creation_date?: string;
  description?: string;
  id?: string;
  name?: string;
}

interface AgencyContextType {
  agency: AgencyValues;
  set: HandleChangeStore;
  get: () => AgencyContextType;
}

export type HandleChangeStore = (
  partial:
    | AgencyContextType
    | Partial<AgencyContextType>
    | ((
        state: AgencyContextType
      ) => AgencyContextType | Partial<AgencyContextType>),
  replace?: boolean | undefined
) => void;

export type HandleChangeValues = (agency: AgencyValues) => void;

export const agencyContext = createLocalZustand<AgencyContextType>(
  (initializer) => (set, get) => ({
    agency: { ...initializer },
    set: set,
    get: get,
  })
);
