import Base from "../base";

const Loading: Component<{
  width?: string;
  height?: string;
  percentage?: number;
  fillDuration?: number;
}> = ({ width = 300, height = 300, percentage, fillDuration = 3, sx }) => {
  const waveHeight = 600;
  const waveWidth = 15000;
  const fullYPosition = -10500;

  let yPosition;
  if (percentage !== null && percentage !== undefined) {
    yPosition = (percentage / 100) * fullYPosition;
  }

  const fixedY = yPosition !== undefined ? ` translateY(${yPosition}px)` : "";

  return (
    <Base
      as="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fillRule="evenodd"
      clipRule="evenodd"
      imageRendering="optimizeQuality"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      viewBox="0 0 21888 19575"
      sx={{ ...sx }}
    >
      <defs>
        <style>
          {`
            @keyframes wave {
                from {
                  transform: translateX(0)${fixedY};
                }
                to {
                  transform: translateX(-${waveWidth}px)${fixedY};
                }
              }
            @keyframes fill {
                0%, 20% {
                    transform: translate(0, ${0}px);
                }
                80%, 100% {
                    transform: translate(0, ${fullYPosition}px);
                }
            }
          `}
        </style>
        <linearGradient
          id="a"
          x1="3175"
          x2="60387"
          y1="15786"
          y2="8137"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#5AC3BE"></stop>
          <stop offset="1" stopColor="#00B1EF"></stop>
        </linearGradient>
        <clipPath id="b">
          <path d="M4696 7858c-85 2682-1 5519-1 8218 0 696 760 1260 1697 1260h1548c937 0 1267-564 1267-1260 0-488-67-2139 96-2455 134-260 402-348 708-361h1972c395 0 718 322 718 718v2098c0 696 330 1260 1267 1260h1548c937 0 1697-564 1697-1260V7891c0-1090-1242-1260-2074-1260h-1171c-937 0-1267 564-1267 1260v1590c0 395-322 718-718 718H9924c-395 0-718-323-718-718V7891c0-696-330-1260-1267-1260H6768c-1 0-2035-129-2073 1226z"></path>
        </clipPath>
      </defs>
      <path
        id="background"
        fill="#91D6D4"
        d="M 3000 7000 L 11000 3000 L 19000 7000 L 19000 18000 L 3000 18000"
      />
      <path
        id="white-h"
        fill="#91D6D4"
        d="M4696 7858c-85 2682-1 5519-1 8218 0 696 760 1260 1697 1260h1548c937 0 1267-564 1267-1260 0-488-67-2139 96-2455 134-260 402-348 708-361h1972c395 0 718 322 718 718v2098c0 696 330 1260 1267 1260h1548c937 0 1697-564 1697-1260V7891c0-1090-1242-1260-2074-1260h-1171c-937 0-1267 564-1267 1260v1590c0 395-322 718-718 718H9924c-395 0-718-323-718-718V7891c0-696-330-1260-1267-1260H6768c-1 0-2035-129-2073 1226z"
      ></path>
      <path
        id="external-container"
        fill="url(#a)"
        d="M19162 6287L12850 721c-1090-961-2703-961-3793 0L0 8708l1774 2016v5984c0 1579 1288 2867 2867 2867h12624c1579 0 2867-1288 2867-2867v-6006l1755-1994c-911-804-1816-1618-2726-2421zm-4024 346l-3236-2854c-545-481-1351-480-1896 0L6770 6633c-1 0-2035-129-2073 1226-85 2682-1 5519-1 8218 0 696 760 1260 1697 1260h1548c937 0 1267-564 1267-1260 0-488-67-2139 96-2455 134-260 402-348 708-361h1972c395 0 718 322 718 718v2098c0 696 330 1260 1267 1260h1548c937 0 1697-564 1697-1260V7892c0-1090-1242-1260-2074-1260z"
      ></path>
      <path
        id="ceiling"
        fill="#91D6D4"
        d="M15138 6633l-3236-2854c-545-481-1351-480-1896 0L6770 6633h1171c937 0 1267 564 1267 1260v1590c0 395 322 718 718 718h2059c395 0 718-322 718-718V7893c0-696 330-1260 1267-1260h1171z"
      ></path>
      <g id="white-h-clip" clipPath="url(#b)">
        <g
          id="waves"
          style={{
            ...(yPosition == undefined && {
              animation: `fill ${fillDuration}s ease-in-out infinite alternate`,
            }),
          }}
        >
          <path
            id="wave-1"
            fill="#FFFFFF88"
            d={`M4611 17000c4044 0 4743-${waveHeight} 7561-${waveHeight}s3781 ${waveHeight} 7561 ${waveHeight}c3781 0 4743-${waveHeight} 7561-${waveHeight}s3517 ${waveHeight} 7561 ${waveHeight}v22108H4609V16062z`}
            style={{ animation: "wave 1s linear infinite" }}
          ></path>
          <path
            id="wave-2"
            fill="#FFFFFF88"
            d={`M4611 17500c4044 0 4743-${waveHeight} 7561-${waveHeight}s3781 ${waveHeight} 7561 ${waveHeight}c3781 0 4743-${waveHeight} 7561-${waveHeight}s3517 ${waveHeight} 7561 ${waveHeight}v22108H4609V16062z`}
            style={{
              animation: "wave 1.5s linear infinite",
            }}
          ></path>
        </g>
      </g>
    </Base>
  );
};

export default Loading;
