import { FlexRow } from "components/basic/flex";
import Header from "layout/app/header";
import SideMenu, { LinkItens, SideMenuApp } from "layout/app/side-menu";
import PageContent from "layout/app/page-content";
import { useState } from "react";
import Base from "components/basic/base";
import { IconBriefcase, IconDashboard, IconHome, IconSettings } from "@tabler/icons-react";
import { AgencyIcon } from "pages/authenticated/manage-agencies/manage-agencies";
import { agencyContext } from "pages/authenticated/manage-agencies/agencies-context";

const AppLayout: ComponentWithChildren = ({ children }) => {
  const [menuVisibility, setMenuVisibility] = useState(true);
  const headerHeight = 80;

  return (
    <Base sx={{ width: "100%" }}>
      <Header {...{ setMenuVisibility, headerHeight }} />
      <FlexRow
        sx={{
          width: "100%",
          height: "100%",
          alignItems: "stretch",
          overflowX: "clip",
        }}
      >
        <SideMenu {...{ menuVisibility, setMenuVisibility, headerHeight }} />
        <PageContent {...{ headerHeight }}>{children}</PageContent>
      </FlexRow>
    </Base>
  );
};

export default AppLayout;

export const ApplicationLayout: ComponentWithChildren = ({ children }) => { //TODO Remove Mock
  const [menuVisibility, setMenuVisibility] = useState(true);
  const headerHeight = 60;

  const linkItens: Record<string, LinkItens> = {
    agencies: {label: "Agencies", linkTo: "/application/agencies", icon: <IconBriefcase stroke={1.5} />},
    settings: {label: "Settings", linkTo: "/application/settings", icon: <IconSettings stroke={1.5} />},
  }

  return (
    <Base sx={{ width: "100%" }}>
      <Header {...{ setMenuVisibility, headerHeight }} />
      <FlexRow
        sx={{
          width: "100%",
          height: "100%",
          alignItems: "stretch",
          overflowX: "clip",
        }}
      >
        <SideMenuApp {...{ menuVisibility, setMenuVisibility, headerHeight, linkItens }} />
        <PageContent {...{ headerHeight }}>{children}</PageContent>
      </FlexRow>
    </Base>
  );
};

export const AgencyLayout: ComponentWithChildren= ({ children }) => {
  const [menuVisibility, setMenuVisibility] = useState(true);
  const headerHeight = 60;

  const { useStore } = agencyContext;
  const agency = useStore((state) => state.agency);

  const linkItens: Record<string, LinkItens> = {
    agency: {label: agency.name || "", linkTo: "", icon: <AgencyIcon agency={agency} fontSize={24}/>},
    dashboard: {label: "Dashboard", linkTo: "dashboard", icon: <IconDashboard stroke={1.5} />},
    properties: {label: "Properties", linkTo: "properties", icon: <IconHome stroke={1.5} />},
    settings: {label: "Settings", linkTo: "settings", icon: <IconSettings stroke={1.5} />},
  }

  return (
    <Base sx={{ width: "100%" }}>
      <Header {...{ setMenuVisibility, headerHeight }} />
      <FlexRow
        sx={{
          width: "100%",
          height: "100%",
          alignItems: "stretch",
          overflowX: "clip",
        }}
      >
        <SideMenuApp {...{ menuVisibility, setMenuVisibility, headerHeight, linkItens }} />
        <PageContent {...{ headerHeight }}>{children}</PageContent>
      </FlexRow>
    </Base>
  );
}
