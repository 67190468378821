import { FlexRow } from "components/basic/flex";
import Checkbox from "../primitives/checkbox";
import Label from "../primitives/label";
import { useRef } from "react";

// TODO Control checkbox by clicking at the label
type CheckboxFieldProps = { name: string };
const CheckboxField: Component<CheckboxFieldProps> = ({ name, label }) => {
  const checkboxRef = useRef<HTMLElement>();
  return (
    <FlexRow sx={{ alignItems: "center", gap: 1, cursor: "pointer" }}>
      <Checkbox name={name} checkboxRef={checkboxRef} />
      <Label
        name={name}
        sx={{ cursor: "inherit" }}
        onClick={() => checkboxRef?.current?.click()}
      >
        {label}
      </Label>
    </FlexRow>
  );
};

export default CheckboxField;
