import TextField from "components/form/custom-fields/text-field";
import { useFormStoreSetters } from "components/form/form-store";
import Button from "components/basic/button";
import Base from "components/basic/base";
import { cepMask, cnpjMask } from "utils/input-masks";
import { FlexColumn } from "components/basic/flex";
import { useState } from "react";

import getCepData, { CepData } from "services/get-cep-data";
import { toast } from "react-toastify";
import LoadingAdornment from "components/form/primitives/input-adornment/loading";
import { agenciesPostRequest } from "pages/authenticated/services/requests";
import { useAuth } from "react-oidc-context";
import { useNavigate } from "react-router";
import { useStepsContext } from "components/steps-controller/steps-context";
import TextAreaField from "components/form/custom-fields/text-area-field";
import FormSubmitBehavior from "components/form/form-submit";

type MaskFunction = (value: string) => {
  unmasked: string;
  masked: string;
};

export const numeroMask: MaskFunction = (value) => {
  const unmasked = value.replace(/\D+/g, "");
  const masked = `N°${unmasked}`;
  return { unmasked, masked };
};

type AgencyProps = {
  nextStepId: string;
};
const RegisterAgency: React.FC<AgencyProps> = ({ nextStepId }) => {
  const [cepLoading, setCepLoading] = useState(false);

  const { updateValues } = useFormStoreSetters();

  const stepsContext = useStepsContext();
  const { setCurrentStepId } = stepsContext;

  const auth = useAuth();

  const [isValidCep, setIsValidCep] = useState(true);

  return (
    <FlexColumn
      sx={{
        gap: 2,
        flexWrap: "nowrap",
        "@media (max-width:600px)": { flexWrap: "wrap" },
      }}
    >
      <Base
        sx={{
          flexGrow: 1,
          display: "grid",
          gridTemplateColumns:
            "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr",
          gap: 1,
          "@media(sm-)": {
            background: "red",
          },
          alignItems: "start",
        }}
      >
        <FlexColumn sx={{ gridColumn: "span 6", gap: 1 }}>
          <TextField name="name" label="Nome comercial" />
          <TextField name="cnpj" label="CNPJ" mask={cnpjMask} placeholder="00.000.000/0000-00"/>
          <TextAreaField
            name="description"
            label="Descrição"
            type="text"
            rows={1}
          />
        </FlexColumn>

        <Base as="span" sx={{ gridColumn: "span 12", height: "0px" }} />

        <TextField
          name="cep"
          label="CEP"
          mask={cepMask}
          placeholder="0000-000"
          sx={{ gridColumn: "span 3" }}
          type="text"
          onFieldChange={({ value }) => onCEPFilled(value)}
          rightAdornment={cepLoading && <LoadingAdornment />}
          disabled={cepLoading}
          validationMoment="blur"
          validation={() => (!isValidCep ? "Digite um CEP válido" : undefined)}
        />

        <TextField
          name="street"
          label="Logradouro"
          sx={{ gridColumn: "span 9" }}
          rightAdornment={cepLoading && <LoadingAdornment />}
          disabled={cepLoading}
        />

        <TextField
          name="number"
          label="Número"
          sx={{ gridColumn: "span 2" }}
          mask={numeroMask}
        />

        <TextField
          name="complement"
          label="Complemento"
          sx={{ gridColumn: "span 3" }}
        />

        <TextField
          name="neighborhood"
          label="Bairro"
          sx={{ gridColumn: "span 7" }}
          rightAdornment={cepLoading && <LoadingAdornment />}
          disabled={cepLoading}
        />

        <TextField
          name="city"
          label="Cidade"
          sx={{ gridColumn: "span 8" }}
          rightAdornment={cepLoading && <LoadingAdornment />}
          disabled={cepLoading}
        />

        <TextField
          name="state"
          label="Estado"
          sx={{ gridColumn: "span 4" }}
          rightAdornment={cepLoading && <LoadingAdornment />}
          disabled={cepLoading}
        />

        <FormSubmitBehavior
          onSubmit={(state) => onSubmitForm(state.formValues)}
        >
          <Button
            sx={{ gridColumn: "span 12", width: "100%" }}
            color="primary.main"
          >
            + Adicionar
          </Button>
        </FormSubmitBehavior>
      </Base>
    </FlexColumn>
  );

  async function onSubmitForm(formValues: Record<string, string>) {
    const response = await agenciesPostRequest(formValues, auth.user?.access_token);
    setCurrentStepId(nextStepId);
  }

  async function onCEPFilled(value: string) {
    if (value.length === 8) {
      try {
        setCepLoading(true);
        const cepData = await getCepData(value);
        const { logradouro, bairro, localidade, uf, cep } =
          cepData?.data as CepData;
        const newValues = {
          cep,
          street: logradouro,
          neighborhood: bairro,
          city: localidade,
          state: uf,
        };
        updateValues(newValues);
        setIsValidCep(true);
      } catch (error) {
        setIsValidCep(false);
        console.error(error);
      } finally {
        setCepLoading(false);
      }
    }
  }
};

export default RegisterAgency;
