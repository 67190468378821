import { CardWithSections } from "components/basic/card/card";
import CardHeader from "components/basic/card/card-header";
import { FlexColumn, FlexRow } from "components/basic/flex";
import Text from "components/basic/text";
import BreadCrumb from "components/composed/breadcrumb";
import {
  AgencyIcon,
  RealStateCard,
  RealStates,
} from "../manage-agencies/manage-agencies";
import { agencyContext } from "../manage-agencies/agencies-context";
import { CardSection } from "components/basic/card/card-section";
import { withAuthenticationRequired } from "react-oidc-context";

const OverviewPage = () => {
  const { useStore } = agencyContext;
  const agency = useStore((state) => state.agency);
  return (
    <>
      <FlexColumn>
        <Text variant="h3">Overview</Text>
        <BreadCrumb />
      </FlexColumn>

      <FlexRow sx={{ gap: 2, flexWrap: "wrap" }}>
        <CardWithSections size="micro" sx={{ flexGrow: 1, p: 1 }}>
          <CardHeader>
            <FlexRow sx={{ gap: 1 }}>
              <AgencyIcon agency={agency} />
              <Text
                sx={{
                  fontSize: 25,
                  fontWeight: 600,
                  whiteSpace: "nowrap",
                  marginBlock: "auto",
                }}
              >
                {agency.name}
              </Text>
            </FlexRow>
          </CardHeader>

          <CardSection>
            <FlexColumn sx={{ gap: 1 }}>
              <FlexRow>
                <Text variant="h6" sx={{ marginBlock: "auto" }}>
                  Status:
                </Text>
                <Text sx={{ px: 1 }}>Ativo</Text>
                {/* TODO trocar para agency.status */}
              </FlexRow>
              <Text variant="h6">Description</Text>
              <Text sx={{ px: 2 }}>{agency.description}</Text>
            </FlexColumn>
          </CardSection>
        </CardWithSections>

        <CardWithSections size="micro" sx={{ flexGrow: 1, p: 1, width: "100px" }}>
          <CardHeader>
            <FlexRow sx={{ gap: 1 }}>
              <Text
                sx={{
                  fontSize: 18,
                  fontWeight: 600,
                  whiteSpace: "nowrap",
                  marginBlock: "auto",
                }}
              >
                Members
              </Text>
            </FlexRow>
          </CardHeader>

          <CardSection>

          </CardSection>
        </CardWithSections>

      </FlexRow>
    </>
  );
};

export default withAuthenticationRequired(OverviewPage);
