import Button, { ButtonProps } from "components/basic//button";

const IconButton: ComponentWithChildren<ButtonProps> = ({
  children,
  sx,
  ...props
}) => {
  return (
    <Button sx={{ p: 0, aspectRatio: "1 / 1", ...sx }} {...props}>
      {children}
    </Button>
  );
};

export default IconButton;
