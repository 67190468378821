import Base from "components/basic/base";
import { useStepsContext } from "./steps-context";

type StepContentProps = { stepId: string };
const StepContent: ComponentWithChildren<StepContentProps> = ({
  stepId,
  sx,
  children,
  ...props
}) => {
  const { currentStepId, setCurrentStepId } = useStepsContext();
  if (currentStepId === stepId)
    return (
      <Base sx={{ display: "contents", ...sx }} {...props}>
        {children}
      </Base>
    );

  return <></>;
};

export default StepContent;
