type JSONServerMethods = "GET" | "POST" | "UPDATE" | "PATCH" | "PUT" | "DELETE";

type JsonServerOptions<EntityType> = {
  method: JSONServerMethods;
  body: EntityType;
  filters?: object;
  id?: string;
  pagination?: JsonServerPagination;
};

type JsonServerPagination = {
  page: string;
  pageSize: string;
};

type JsonServerMetadata = {
  first: number;
  prev: number | null;
  next: number | null;
  last: number;
  pages: number;
  items: number;
};

type JsonServerSearchResponse<T> = JsonServerMetadata & { data: T };
type JsonServerSearchResult<T> = { metadata: JsonServerMetadata; data: T };

async function fetchJsonServer<EntityType>(
  serverUrl: string,
  entity: string,
  options: Partial<JsonServerOptions<EntityType>>
): Promise<EntityType> {
  const { method, body, filters, id, pagination } = options;
  const idParam = id ? `/${id}` : "";

  const queryParams: Record<string, string> = {};

  if (pagination) {
    queryParams["_page"] = String(pagination.page ?? 1);
    queryParams["_per_page"] = String(pagination.pageSize ?? 25);
  }

  let queryParamsString = Object.entries(queryParams)
    .map(([key, value]) => `${key}=${value}`)
    .join("&");
  if (queryParamsString !== "") queryParamsString = "?" + queryParamsString;

  const fullUrl = `${serverUrl}/${entity}${idParam}${queryParamsString}`;
  const response = await fetch(fullUrl, {
    method,
    body: body && JSON.stringify(body),
  });

  return await response.json();
}

export function generateCrudFunctions<EntityType>(
  serverUrl: string,
  entity: string
) {
  type Options = JsonServerOptions<EntityType>;
  type SearchResult = JsonServerSearchResult<EntityType>;
  type SearchResponse = JsonServerSearchResponse<EntityType>;

  const search = async (
    options?: Pick<Options, "filters" | "pagination">
  ): Promise<SearchResult> => {
    const response = await fetchJsonServer<SearchResponse>(serverUrl, entity, {
      method: "GET",
      ...options,
    });

    const { data, ...metadata } = response;
    const result = { data, metadata } as JsonServerSearchResult<EntityType>;
    return result;
  };

  const getById = (options?: Pick<Options, "id">): Promise<EntityType> =>
    fetchJsonServer<EntityType>(serverUrl, entity, {
      method: "GET",
      ...options,
    });

  const insert = (options: Pick<Options, "body">) =>
    fetchJsonServer<EntityType>(serverUrl, entity, {
      method: "POST",
      ...options,
    });

  const update = (options: Required<Pick<Options, "body" | "id">>) =>
    fetchJsonServer<EntityType>(serverUrl, entity, {
      method: "PUT",
      ...options,
    });

  const patch = (options: Required<Pick<Options, "body" | "id">>) =>
    fetchJsonServer<EntityType>(serverUrl, entity, {
      method: "PATCH",
      ...options,
    });

  const exclude = (options: Required<Pick<Options, "id">>) =>
    fetchJsonServer<EntityType>(serverUrl, entity, {
      method: "DELETE",
      ...options,
    });

  return {
    search,
    getById,
    insert,
    exclude,
    update,
    patch,
  };
}
