import { FlexColumn } from "components/basic/flex";
import Label from "../primitives/label";
import Card from "components/basic/card/card";
import { ThemeUIStyleObject } from "theme-ui";

type FieldGroupProps = {
  name?: string;
  groupId?: string;
  label: string;
  direction?: "horizontal" | "vertical";
  cardSx?: ThemeUIStyleObject;
  wrapperSx?: ThemeUIStyleObject;
};
const FieldGroup: ComponentWithChildren<FieldGroupProps> = ({
  name = "",
  groupId,
  label,
  direction = "vertical",
  children,
  cardSx,
  wrapperSx,
  ...props
}) => {
  return (
    <FlexColumn sx={{ ...wrapperSx }}>
      <Label name={name}>{label}</Label>
      <Card
        addOutline
        size="micro"
        variant="none"
        sx={{
          flexDirection: direction == "vertical" ? "column" : "row",
          gap: 1,
          borderRadius: 2,
          ...cardSx,
        }}
      >
        {children}
      </Card>
    </FlexColumn>
  );
};

export default FieldGroup;
