import React from "react";
import Card, { CardWithSections } from "components/basic/card/card";
import { CardSection } from "components/basic/card/card-section";
import { FlexColumn, FlexFill, FlexRow } from "components/basic/flex";
import Text from "components/basic/text";
import separateValueToStrings from "utils/separate-decimal-number";
import { IconCheck, IconInfinity } from "@tabler/icons-react";
import Icon from "components/basic/icon";
import Button from "components/basic/button";

type Feature = {
  label: string;
  quantity?: number | "infinite";
  check?: boolean;
  value?: number;
};

export type PriceCardProps = {
  icon: React.FunctionComponent;
  title: string;
  price: number;
  discount: number;
  features: Feature[];
  variant?: "filled" | "outlined";
  backgroundVariant?: number;
  href: string;
};
const PriceCard2: Component<PriceCardProps> = ({
  icon,
  title,
  price,
  features,
  variant = "filled",
  backgroundVariant = 1,
  href,
  discount,
  sx,
}) => {
  const discountedPrice = price * ((100 - discount) / 100);
  const splittedDiscountedPrice = separateValueToStrings(discountedPrice);
  const splittedPrice = separateValueToStrings(price);
  return (
    <CardWithSections
      size="small"
      sx={{
        minWidth: "225px",
        cursor: "pointer",
        transition: "transform 0.2s",
        border: "1px solid transparent",
        mb: "2px",
        "&:hover": {
          background: "neutral.n1",
          transform: "scale(1.02)",
          borderColor: "primary.main",
        },
      }}
    >
      <CardSection
        addLineSeperator
        sx={{ gap: 1, background: "primary.main", flexWrap: "nowrap" }}
      >
        <Card
          size="micro"
          variant="none"
          sx={{ background: "background.paper", flexShrink: 0 }}
        >
          <Icon as={icon} sx={{ color: "primary.dark2" }} />
        </Card>
        <FlexColumn sx={{ justifyContent: "center" }}>
          <Text
            sx={{ color: "lighten.n8", fontSize: 0, fontFamily: "Calibri" }}
          >
            For Individuals
          </Text>
          <Text sx={{ color: "white", fontSize: 3, fontWeight: "700" }}>
            {title}
          </Text>
        </FlexColumn>
      </CardSection>

      <CardSection addLineSeperator sx={{ py: 2 }} direction="vertical">
        <FlexRow sx={{ alignItems: "flex-end", justifyContent: "center" }}>
          <Text
            variant="h4"
            sx={{ color: "text.faded", fontWeight: "regular", mr: "5px" }}
          >
            R$
          </Text>

          <Text variant="h2" sx={{}}>
            {splittedDiscountedPrice[0]}
          </Text>

          <Text variant="h3">,{splittedDiscountedPrice[1]}</Text>

          <Text sx={{ color: "text.faded" }}>/month</Text>
        </FlexRow>

        {discount > 0 && (
          <FlexRow
            sx={{
              alignItems: "flex-end",
              justifyContent: "center",
              textDecoration: "line-through",
            }}
          >
            <Text variant="h4" sx={{ color: "text.faded" }}>
              {splittedPrice[0]},{splittedPrice[1]}
            </Text>
          </FlexRow>
        )}
      </CardSection>

      <CardSection direction="vertical" sx={{ gap: 1, pt: 1 }}>
        {features.map((feature) => (
          <Feature key={feature.label} {...feature} />
        ))}
        <Button variant="soft">Assinar</Button>
      </CardSection>
    </CardWithSections>
  );
};

export const Feature: Component<Feature> = ({
  label,
  value,
  check,
  quantity,
}) => {
  let splittedValue;
  if (value !== undefined)
    splittedValue = separateValueToStrings(value as number);

  return (
    <FlexRow
      sx={{
        fontSize: 0,
        borderStyle: "solid",
        borderWidth: "0 0 1px 0",
        borderColor: "darken.n1",
        gap: 1,
        pb: 1,
        alignItems: "center",
        "&:nth-last-child(1)": { border: "none", pb: 0 },
      }}
    >
      <Text>{label}</Text>

      <FlexFill />

      {value !== undefined && (
        <Text>
          <span style={{ fontSize: "0.85em" }}>R$</span>
          {splittedValue?.[0]},{splittedValue?.[1]}
        </Text>
      )}

      {check !== undefined && <Icon as={IconCheck} sx={{ color: "green" }} />}

      {quantity === "infinite" && (
        <Icon as={IconInfinity} sx={{ color: "neutral.n5" }} />
      )}

      {quantity !== undefined && quantity !== "infinite" && (
        <Text>{quantity}</Text>
      )}
    </FlexRow>
  );
};

export default PriceCard2;
