import { useContext } from "react";
import { Flex } from "../flex";
import { CardProps, CardWithSectionsContext, cardSizeToSx } from "./card";

export type CardSectionProps = {
  size?: CardProps["size"];
  variant?: "none" | "light-contrast";
  direction?: CardProps["direction"];
  addLineSeperator?: boolean;
};
export const CardSection: ComponentWithChildren<CardSectionProps> = ({
  variant = "none",
  addLineSeperator = false,
  direction,
  size,
  children,
  sx,
}) => {
  const { size: contextSize } = useContext(CardWithSectionsContext);
  const { direction: contextDirection = "horizontal" } = useContext(
    CardWithSectionsContext
  );

  const finalDirection = direction ?? contextDirection;

  return (
    <Flex
      sx={{
        width: "100%",
        borderRadius: 0,
        flexDirection: finalDirection == "horizontal" ? "row" : "column",
        flexWrap: finalDirection == "horizontal" ? "wrap" : "nowrap",
        alignItems: "flex-start",
        ...cardSizeToSx(size ?? contextSize),
        ...(addLineSeperator && {
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderStyle: "solid",
          borderWidth: "0 0 1px 0",
          borderColor: "rgba(0,0,0,0.1)",
        }),
        ...(variant === "light-contrast" && { background: "rgba(0,0,0,0.05)" }),
        ...sx,
      }}
    >
      {children}
    </Flex>
  );
};
