import TextField, {
  TextFieldProps,
} from "components/form/custom-fields/text-field";
import InputAdornment from "components/form/primitives/input-adornment";
import { BiSearch } from "react-icons/bi";

export type SearchBarProps = {
  name?: string;
  onChange: (e: React.FormEvent<HTMLInputElement>) => void;
};

export const SearchField: Component = ({ name, onChange = () => {}, sx }) => {
  return (
    <TextField
      name={name ?? "search"}
      onChange={onChange}
      leftAdornment={
        <InputAdornment
          variant="button"
          buttonVariant="outlined"
          sx={{ color: "text.main" }}
        >
          <BiSearch />
        </InputAdornment>
      }
      sx={{ flexGrow: 1, flexShrink: 1, border: "none", ...sx }}
    />
  );
};
